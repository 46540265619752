import axios from 'axios';
// const axios = require('axios');

import YouTip from './YouTip';
import { HQ_URL } from './constants';

const cachedInstanceData = {};
const cachedInstanceSlugMap = {};

// const BASE_URL = 'http://localhost:8080/api';
// const BASE_URL =  '/api';
//const BASE_URL = '';
const BASE_URL = process.env.NODE_ENV === 'development' ? '' : '/api';

const ApiClient = {
  emailReceipt: async ({ to, paymentId, useStripeReceipt, stripeAlt }) => {
    if (!to || !paymentId)
      return null;

    let bodyData = {
      to,
      paymentId
    };

    if (useStripeReceipt) {
      if (stripeAlt) bodyData.stripeAlt = stripeAlt;

      return await axios.post(`${BASE_URL}/sendStripeReceipt`, bodyData)
        .then(resp => resp.data)
        .catch(err => {
          console.warn(`-> [error] could not update stripe receipt_email on intent to ${to}`);
        });
    }


    if (process.env.NODE_ENV === 'development')// ? '?forceCors=true' : '';
      bodyData.forceCors = true;
    if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'demo')
      bodyData.forceKey = 'test';

    return await axios.post(`${HQ_URL}/emails/receipt`, bodyData)
      .then(resp => resp.data)
      .catch(err => {
        console.warn(`-> [error] could not send email receipt to ${to}`);
      });
  },

  fetchInstanceData: async (instanceId) => {
    return await axios.get(`${BASE_URL}/instances/${instanceId}`)
      .then(resp => resp.data)
      .catch(err => console.warn('-> [error] could not retrieve instance data for id', instanceId, err));
  },

  fetchInstanceBySlug: async (slug) => {
    // return await axios.get(`${BASE_URL}/instances`)
    return await axios.get(`${BASE_URL}/instances`, {
      params: {
        slug: slug
      }
    })
      .then(resp => resp.data)
      .catch(err => {
        console.warn('-> [error] could not retrieve instance data for slug: ' + slug, err);
      });
  },

  fetchReviews: async (instanceId) => {
    return await axios.get(`${BASE_URL}/instances/${instanceId}/reviews`)
      .then(resp => resp.data)
      .catch(err => console.warn(`-> [error] could not retrieve reviews for ${instanceId}`, err));
  },

  createReview: async ({ instanceUuid, reviewId, stars, comments, paymentId }) => {
    return await axios.post(`${BASE_URL}/instances/${instanceUuid}/reviews`, { stars, comments, reviewId, paymentId })
      .then(resp => resp.data)
      .catch(err => console.warn(`-> [error] could not insert review for ${instanceUuid}`, err));
  },

  getInstanceBySlug: async (slug) => {
    let instanceId = cachedInstanceSlugMap[slug];

    if (!instanceId) {
      const data = await ApiClient.fetchInstanceBySlug(slug);
      instanceId = data.uuid;
      cachedInstanceData[instanceId] = data;
      cachedInstanceSlugMap[slug] = instanceId;
    }

    return cachedInstanceData[instanceId];
  },

  getInstanceById: async (instanceId) => {
    // if (cachedInstanceData[instanceId]) return cachedInstanceData[instanceId];
    if (!cachedInstanceData[instanceId]) {
      const data = await ApiClient.fetchInstanceData(instanceId);
      if (data.uuid !== instanceId) {
        console.warn('-> [error] fetched instance id (' + data.uuid + ') does not match requested id: ', instanceId);
        return null;
      }

      cachedInstanceData[instanceId] = data;
      cachedInstanceSlugMap[data.slug] = instanceId;
    }

    return cachedInstanceData[instanceId];
    // connect to api
    // GET /api/instances/:entityId
    // return the json body returned
    // __should contain:
    //   .id, .provider?, .recipient (that contains provider maybe..?), .fees, ...
  },

  createToken: async () => {
    console.warn('<<< NYI >>> ApiClient.createToken needs code.');
  },

  charge: async (data) => {
    // console.info('-> ApiClient.charge data: ', data, ' instance: ', YouTip.currentInstance());
    const resp = await axios.post(`${BASE_URL}/instances/${YouTip.currentInstance().uuid}/charge`, data);
    return resp.data;
  },

  createIntent: async (data) => {
    if (!data || !data.amount) {
      return { error: 'attempting to create intent without valid amount/data' };
    }

    // console.info('-> ApiClient.createIntent data: ', data, ' instance uuid: ', YouTip.currentInstance().uuid);
    const resp = await axios.post(
      `${BASE_URL}/instances/${YouTip.currentInstance().uuid}/secret`,
      data
    );
    return resp.data;
  },

  updateIntent: async (data) => {
    const resp = await axios.post(`${BASE_URL}/instances/${YouTip.currentInstance().uuid}/update`, data);
    return resp.data;
  }
};

export default ApiClient;
