import { Grid } from 'semantic-ui-react';

const YouTipLogo = () => <div style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
                           <img id="client-logo" src="logo.png" height="25" alt="youtip" />
                         </div>;

const AppHeader = (props) => {
  const logo = props.theme
        ? <img id="client-logo" src={`/themes/${props.theme}/logo.png`} height="25" alt="" />
        : <YouTipLogo />;

  return (
      <Grid.Row columns={1} className='youtip-header'>
      <Grid.Column className="client-logo-box">
          {logo}
        </Grid.Column>
      </Grid.Row>
  );
};

export default AppHeader;
