import React from 'react';
import YouTip from './YouTip';
import { MINIMUM_TIP } from './constants';
import ApiClient from './ApiClient';

export const YouTipContext = React.createContext();

const utils = {
  meetsMinimum: (cents) => {
    // parseInt here or some other way to ensure it is a number?
    return cents && cents >= utils.minimum();
  },
  minimum: () => {
    const instanceMinimum = YouTip.currentInstance().minimum;
    return instanceMinimum || MINIMUM_TIP;
  },
  centsToString: (cents, { currency = 'usd', locale = 'en-US' }) => {
    return ((cents < 0 ? 0 : cents) / 100).toLocaleString(locale, {
      style: 'currency',
      currency
    });
  },
  calculateTransactionFee: (transactionCents = 0, fees = []) => {
    if (transactionCents <= 0 || fees.length < 1)
      return 0;

    // console.debug(`-> calculate transaction fee for ${transactionCents} w/fees: `, fees);

    let transactionFee = 0;

    fees.forEach(fee => {
      const {
        adjustment_type,
        adjustment_value,
        min,
        max
      } = fee;
      let feeAmount = 0;
      const numericAdjustment = parseFloat(adjustment_value);

      if (numericAdjustment > 0) {
        feeAmount = 0;
        let applyFee = true;

        const numericMin = parseFloat(min);
        const numericMax = parseFloat(max);

        if ((numericMin && transactionCents < numericMin) ||
            (numericMax && transactionCents > numericMax)) {
          applyFee = false;
        }

        if (applyFee) {
          feeAmount = adjustment_type === 'cents'
            ? numericAdjustment
            : Math.round(transactionCents * (numericAdjustment / 100));
        }

        transactionFee += feeAmount;
      }
    });

    return transactionFee;
  },
  calculateTransactionTotal: (transactionCents, feesList) => {
    const transactionFee = utils.calculateTransactionFee(transactionCents, feesList);
    return transactionCents += transactionFee;
  },
  calculateTransactionFeeAndTotal: (transactionCents, feesList) => {
    const fee = utils.calculateTransactionFee(transactionCents, feesList);
    return {
      fee,
      total: transactionCents += fee
    };
  }
};
/*
// abstract manner of things would be to extend a base class..
class PaymentProvider {
  createTransaction() {}
  updateTransaction() {}
  executeTransaction() {}
  render() {}
};
*/

export class StripePaymentProvider {

  _paymentIntent: null
  _paymentRequest: null
  _clientSecret: null

  async findOrCreatePaymentIntent({ transaction, currency, currentInstance }) {
    let targetCurrency = currency ? currency : currentInstance.currency;
    let intent = this.getPaymentIntent();
    if (!intent)
      intent = await this.createPaymentIntent({ transaction, currency: targetCurrency, currentInstance });

    return intent;
  }

  async createPaymentIntent({ transaction, currency, currentInstance }) {
    let targetCurrency = currency ? currency : currentInstance.currency;
    const intentResponse = await ApiClient.createIntent({
      amount: transaction.tip, // <-- fees will be calculated on the backend
      currency: targetCurrency,
      description: currentInstance.displayName,
      metadata: Object.assign(
        {},
        { ...currentInstance.getMetadata() },
        {
          transaction: JSON.stringify({
            tip: transaction.tip,
            fee: transaction.fee,
            total: transaction.total
          })
        }
      )
    });

    if (!intentResponse)
      return { error: `Failed to create new payment intent. `};

    if (intentResponse.error) {
      console.debug(`-> [ERROR] error creating payment intent`, intentResponse);
      return { error: intentResponse.error };
    }

    this._paymentIntent = intentResponse;
    this._clientSecret = intentResponse.client_secret;

    return this.getPaymentIntent();
  }

  getPaymentIntent() {
    return this._paymentIntent;
  }

  getClientSecret() {
    return this._paymentIntent.client_secret;
  }



  async updatePaymentIntent({ transaction, currentInstance }) {
    const intentResponse = await ApiClient.updateIntent({
      id: this._paymentIntent.id,
      amount: transaction.tip, // <-- fees calculated on backend
      description: currentInstance.displayName,
      metadata: Object.assign(
        {},
        { ...currentInstance.getMetadata() },
        {
          transaction: JSON.stringify({
            tip: transaction.tip,
            fee: transaction.fee,
            total: transaction.total
          })
        }
      )
    });

    if (!intentResponse)
      return { error: `Failed to update payment intent w/id: ${this._paymentIntent.id}` };

    if (intentResponse.error) {
      console.log(`-> [ERROR] error updating payment intent `, this.__paymentIntent.id, intentResponse.error);
      return { error: intentResponse.error };
    }

    this._paymentIntent = intentResponse;
    return this.getPaymentIntent();//._paymentIntent;
  }

  // findOrCreatePaymentRequest() {}
  // supportsDigitalWallet() {}
  // requestDigitalWallet() {}

  validTipDidChange(transaction) {
    // console.debug(`=$ valid tip did change fired in stripe provider.`, transaction);
  }
};

export class YouTipContextProvider extends React.Component {
  constructor(props) {
    super(props);

    this._chainedPromise = null;
    this._tipInputElement = null;
    this._locked = false;
    this._locks = 0;
    this._dirty = false;

    // TODO: this will need a bit more abstraction when we add more than stripe
    // this.paymentProvider = new WhomeverPaymentProvider();

    this.getTipInputElement = this.getTipInputElement.bind(this);
    this.handlePaymentSelection = this.handlePaymentSelection.bind(this);
    this.handleTipChange = this.handleTipChange.bind(this);
    this.readyToPerformTransaction = this.readyToPerformTransaction.bind(this);
    this.setCurrentInstance = this.setCurrentInstance.bind(this);
    this.whenReady = this.whenReady.bind(this);
    this.whenReadyBang = this.whenReadyBang.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.isDirty = this.isDirty.bind(this);
    this.lockUi = this.lockUi.bind(this);
    this.unlockUi = this.unlockUi.bind(this);

    this.state = {
      currentInstance: null,
      transaction: {
        tip: 0,
        fee: 0,
        total: 0,
        completed: false,
        completedAt: null,
      },
      payment: {},
      paymentProvider: null,
      paymentData: null,
      stripePayments: new StripePaymentProvider(),
      uiDisabled: false,
      uiMessage: null,
      isDirty: this.isDirty,
    };
  }

  contextValue() {
    const {
      handlePaymentSelection,
      handleTipChange,
      setCurrentInstance,
      whenReady,
      whenReadyBang,
      readyToPerformTransaction,
      saveChanges,
      lockUi,
      unlockUi
    } = this;

    return Object.assign(
      {},
      this.state,
      {
        handlePaymentSelection,
        handleTipChange,
        setCurrentInstance,
        whenReady,
        whenReadyBang,
        readyToPerformTransaction,
        saveChanges,
        lockUi,
        unlockUi,
        utils
      }
    );
  }

  lockUi() {
    this._locks += 1;

    // const prefix = '_'.padStart(this._locks * 2, '_');
    // console.debug(`${prefix}+ ui lock ${this._locks}`);
    if (!this._locked) {
      this._locked = true;
      this.setState({ uiDisabled: true });
    }
  }

  unlockUi() {
    // const prefix = '_'.padStart(this._locks * 2, '_');
    // console.debug(`${prefix}- ui unlock ${this._locks}`);

    if (this._locks > 0)
      this._locks -= 1;

    if (this._locked) {
      this._locked = false;
      this.setState({ uiDisabled: false });
    }
  }

  readyToPerformTransaction() {
    const { currentInstance, transaction } = this.state;
    return currentInstance && transaction && utils.meetsMinimum(transaction.tip);// && !this.isDirty();
  }

  getTipInputElement() {
    return this._tipInputElement;
  }

  registerTipInputElement(el) {
    if (!el) {
      console.warn(`-> error registering tip input element: no element provided.`);
      return;
    }

    // console.debug(`-> registered tip input component instance.`);
    this._tipInputElement = el;
  }

  getCurrentInstance() {
    const { currentInstance } = this.state;
    return currentInstance;
  }

  setCurrentInstance(slug) {
    return YouTip.setCurrentInstance(slug).then(instanceData => {
      this.setState({ currentInstance: instanceData });
    });
  }

  changeTip(cents) {
    // console.debug(`-> tip changing to ${cents} cents`);

    const { currentInstance, transaction: { tip, fee, total } } = this.state;
    if (!currentInstance || cents === tip) {
      return this.state.transaction;
    }

    const feeAndTotal = utils.meetsMinimum(cents)
          ? (currentInstance.feesAreCovered ? 0 : utils.calculateTransactionFeeAndTotal(cents, currentInstance.fees))
          : { fee, total };

    const newTransactionInfo = Object.assign(
      {}, this.state.transaction, { tip: cents }, feeAndTotal
    );

    this._dirty = true;
    this.setState({
      transaction: newTransactionInfo
    });

    // this.state.paymentProvider.validTipDidChange(newTransactionInfo);
    this.state.stripePayments.validTipDidChange(newTransactionInfo);
    return newTransactionInfo;
  }

  isDirty() {
    return this._dirty;
  }

  handlePaymentSelection(paymentType, paymentData) {
    console.debug(`-> handling payment selection [_${paymentType}_]; payment response: `, paymentData);
    const { paymentMethod } = paymentData;

    let method = paymentType;
    if (paymentType === 'browser') {
      if (paymentMethod.card && paymentMethod.card.wallet) {
        method = paymentMethod.card.wallet.type;
        if (method)
          method = method.toUpperCase().replace("_", " ");
      }
    }

    this.setState({
      transaction: Object.assign({}, this.state.transaction, {
        completed: true,
        completedAt: Date.now()
      }),
      payment: Object.assign({}, { method }, paymentData)
    });


  }

  handleTipChange(cents) {
    //
    // perform validation; hold error message as necessary
    //
    return this.changeTip(cents);
  }

  async saveChanges() {
    const { stripePayments, transaction, currentInstance } = this.state;

    if (!utils.meetsMinimum(transaction.tip)) {
      // console.debug(`-> saveChanges being ignored, tip amount ${transaction.tip} below minimum`);
      return null;
    }

    let intent = stripePayments.getPaymentIntent();
    if (!intent) {
      intent = await stripePayments.createPaymentIntent({ transaction, currentInstance });
      this._dirty = false;
      return intent;
    }

    const dirtyMetadata = Object.keys(currentInstance.metadata)
          .some(k => intent.metadata[k] !== currentInstance.metadata[k]);

    if (!this._dirty && !dirtyMetadata) {
      return intent;
    }

    intent = await stripePayments.updatePaymentIntent({ transaction, currentInstance });
    this._dirty = false;
    return intent;
  }

  handlePaymentMade() {
    console.debug(`-> *** handlePaymentMade`);
  }

  whenReadyBang(func) {
    return this.whenReady().then(func);
  }

  // returns a promise, which will be used to help ensure appropriate timing and order elsewhere
  async whenReady() {
    const temp = this._chainedPromise ? await this._chainedPromise : null;
    this._chainedPromise = new Promise((resolve, reject) => {
      resolve(temp);
    });

    return this._chainedPromise;

    /*
    if (!this._chainedPromise || !this._chainedPromise.then)
      this._chainedPromise = new Promise((resolve, reject) => resolve({}));
    return await this._chainedPromise;
    */
  }

  render() {
    return <YouTipContext.Provider value={this.contextValue()}>
             {this.props.children}
           </YouTipContext.Provider>;
  }
};
