import './CaliPrivacyPolicy.css';

const CaliPrivacyPolicy = () => {
  return (
    <div id="cali-privacy-policy" style={{wordWrap:'break-word'}}>
      <div className="WordSection1">
        <p className="DocumentTitle"><a id="a540548"> </a>Privacy Policy for California Residents</p>
        <p className="Paragraph"><b>Effective Date:</b> January 29, 2021</p>
        <p className="Paragraph"><b>Last Updated</b>: January 29, 2021</p>
        <p className="Paragraph">This <b>Privacy Policy for California Residents</b> supplements
          the information contained in youtip Inc.’s Website Privacy Policy
          and applies solely to all visitors, users, and others who reside in the State
          of California (“<b>consumers</b>” or “<b>you</b>”). We adopt this notice to comply
          with the California Consumer Privacy Act of 2018 (“<b>CCPA</b>”) and any terms
          defined in the CCPA have the same meaning when used in this Policy. </p>

        <p className="CustomizableHeading"><a id="a871356"> </a><u>Information We Collect</u></p>
        <p className="Paragraph">We collect information that identifies, relates to,
          describes, references, is reasonably capable of being associated with, or could
          reasonably be linked, directly or indirectly, with a particular consumer,
          household, or device (“<b>personal information</b>”). Personal information does
          not include:</p>

    <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Publicly
          available information from government records.</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Deidentified or
          aggregated consumer information.</p>

    <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Information
          excluded from the CCPA’s scope, like:</p>

        <p className="BulletList2"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>personal
          information covered by certain sector-specific privacy laws, including the Fair
          Credit Reporting Act (FCRA), the Gramm-Leach-Bliley Act (GLBA) or California
          Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection
          Act of 1994.</p>

        <p className="Paragraph">In particular, we have collected the following categories of
          personal information from consumers within the last twelve (12) months: </p>

        <p className="Paragraph">&nbsp;</p>

        <table className="MsoNormalTable" border="0" cellSpacing="0" cellPadding="0" width="100%">
          <tbody><tr>
            <td width="20%" valign="top" style={{width:'20.0%',border:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph" align="center" style={{textAlign:'center'}}><b>Category</b></p>
            </td>
            <td width="60%" valign="top" style={{width:'60.0%',border:'solid windowtext 1.0pt',borderLeft:'none',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph" align="center" style={{textAlign:'center'}}><b>Examples</b></p>
            </td>
            <td width="20%" valign="top" style={{width:'20.0%',border:'solid windowtext 1.0pt',borderLeft:'none',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph" align="center" style={{textAlign:'center'}}><b>Collected</b></p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top" style={{width:'20.0%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">A. Identifiers.</p>
            </td>
            <td width="60%" valign="top" style={{width:'60.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">A real name, alias, postal address, unique personal
                identifier, online identifier, Internet Protocol address, email address,
                account name, Social Security number, driver's license number, passport
                number, or other similar identifiers.</p>
            </td>
            <td width="20%" valign="top" style={{width:'20.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph" align="center" style={{textAlign:'center'}}>YES</p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top" style={{width:'20.0%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">B. Personal information categories listed in the
                California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</p>
            </td>
            <td width="60%" valign="top" style={{width:'60.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">A name, signature, Social Security number, physical
                characteristics or description, address, telephone number, passport number,
                driver's license or state identification card number, insurance policy
                number, education, employment, employment history, bank account number,
                credit card number, debit card number, or any other financial information,
                medical information, or health insurance information.</p>
              <p className="Paragraph">Some personal information included in this category may
                overlap with other categories.</p>
            </td>
            <td width="20%" valign="top" style={{width:'20.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph" align="center" style={{textAlign:'center'}}>YES</p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top" style={{width:'20.0%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">C. Protected classification characteristics under
                California or federal law.</p>
            </td>
            <td width="60%" valign="top" style={{width:'60.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">Age (40 years or older), race, color, ancestry, national
                origin, citizenship, religion or creed, marital status, medical condition,
                physical or mental disability, sex (including gender, gender identity, gender
                expression, pregnancy or childbirth and related medical conditions), sexual
                orientation, veteran or military status, genetic information (including
                familial genetic information).</p>
              <p className="Paragraph">&nbsp;</p>
            </td>
            <td width="20%" valign="top" style={{width:'20.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph" align="center" style={{textAlign:'center'}}>NO</p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top" style={{width:'20.0%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">D. Commercial information.</p>
            </td>
            <td width="60%" valign="top" style={{width:'60.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">Records of personal property, products or services
                purchased, obtained, or considered, or other purchasing or consuming
                histories or tendencies.</p>
            </td>
            <td width="20%" valign="top" style={{width:'20.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph" align="center" style={{textAlign:'center'}}>NO</p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top" style={{width:'20.0%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">E. Biometric information.</p>
            </td>
            <td width="60%" valign="top" style={{width:'60.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">Genetic, physiological, behavioral, and biological
                characteristics, or activity patterns used to extract a template or other
                identifier or identifying information, such as, fingerprints, faceprints, and
                voiceprints, iris or retina scans, keystroke, gait, or other physical
                patterns, and sleep, health, or exercise data.</p>
            </td>
            <td width="20%" valign="top" style={{width:'20.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph" align="center" style={{textAlign:'center'}}>NO</p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top" style={{width:'20.0%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">F. Internet or other similar network activity.</p>
            </td>
            <td width="60%" valign="top" style={{width:'60.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">Browsing history, search history, information on a
                consumer's interaction with a website, application, or advertisement.</p>
            </td>
            <td width="20%" valign="top" style={{width:'20.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph" align="center" style={{textAlign:'center'}}>NO</p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top" style={{width:'20.0%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">G. Geolocation data.</p>
            </td>
            <td width="60%" valign="top" style={{width:'60.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">Physical location or movements. </p>
            </td>
            <td width="20%" valign="top" style={{width:'20.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph" align="center" style={{textAlign:'center'}}>NO</p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top" style={{width:'20.0%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">H. Sensory data.</p>
            </td>
            <td width="60%" valign="top" style={{width:'60.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">Audio, electronic, visual, thermal, olfactory, or similar
                information.</p>
            </td>
            <td width="20%" valign="top" style={{width:'20.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph" align="center" style={{textAlign:'center'}}>NO</p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top" style={{width:'20.0%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">I. Professional or employment-related information.</p>
            </td>
            <td width="60%" valign="top" style={{width:'60.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">Current or past job history or performance evaluations.</p>
            </td>
            <td width="20%" valign="top" style={{width:'20.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph" align="center" style={{textAlign:'center'}}>YES</p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top" style={{width:'20.0%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">J. Non-public education information (per the Family
                Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part
                99)).</p>
            </td>
            <td width="60%" valign="top" style={{width:'60.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">Education records directly related to a student maintained
                by an educational institution or party acting on its behalf, such as grades,
                transcripts, class lists, student schedules, student identification codes,
                student financial information, or student disciplinary records. </p>
            </td>
            <td width="20%" valign="top" style={{width:'20.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
    <p className="Paragraph" align="center" style={{textAlign:'center'}}>NO</p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top" style={{width:'20.0%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">K. Inferences drawn from other personal information.</p>
            </td>
            <td width="60%" valign="top" style={{width:'60.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph">Profile reflecting a person’s preferences,
                characteristics, psychological trends, predispositions, behavior, attitudes,
                intelligence, abilities, and aptitudes.</p>
            </td>
            <td width="20%" valign="top" style={{width:'20.0%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.4pt 0in 5.4pt'}}>
              <p className="Paragraph" align="center" style={{textAlign:'center'}}>NO</p>
            </td>
    </tr>
    </tbody>
        </table>

        <p className="Paragraph">We obtain the categories of personal information listed
          above from the following categories of sources:</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Directly from
          you. For example, from forms you complete through the Website.</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Indirectly from
          you. For example, from observing your actions on our Website.</p>

        <p className="CustomizableHeading"><a id="a748479"> </a><u>Use of Personal Information</u></p>

        <p className="Paragraph">We may use or disclose the personal information we collect
          for one or more of the following purposes: </p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>To fulfill or
          meet the reason you provided the information. For example, if you share your
          name and contact information to ask a question about what we do, we will use
          that personal information to respond to your inquiry. If you provide your
          personal information to make a tip, we will use that information to process
          your request. </p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>To provide,
          support, personalize, and develop our Website, products, and services.</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>To process your
          requests, transactions, and payments and prevent transactional fraud.</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>To provide you
          with support and to respond to your inquiries, including to investigate and
          address your concerns and monitor and improve our responses.</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>To personalize
          your Website experience and to deliver content and product and service
          offerings relevant to your interests, including targeted offers and ads through
          our Website, third-party sites, and via email or text message (with your
          consent, where required by law).</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>To help maintain
          the safety, security, and integrity of our Website, technology assets, and
          business.</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>For testing,
          research, analysis, and product development, including to develop and improve
          our Website and services.</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>To respond to law
          enforcement requests and as required by applicable law, court order, or governmental
          regulations.</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>As described to
          you when collecting your personal information or as otherwise set forth in the
          CCPA.</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>To evaluate or
          conduct a merger, divestiture, restructuring, reorganization, dissolution, or
          other sale or transfer of some or all of our assets, whether as a going concern
          or as part of bankruptcy, liquidation, or similar proceeding, in which personal
          information held by us about our Website users is among the assets transferred.</p>

        <p className="Paragraph">We will not collect additional categories of personal
          information or use the personal information we collected for materially
          different, unrelated, or incompatible purposes without providing you notice.</p>

        <p className="CustomizableHeading"><a id="a831891"> </a><u>Sharing Personal Information</u></p>

        <p className="Paragraph">We may share your personal information by disclosing it to a
          third party for a business purpose. We only make these business purpose
          disclosures under written contracts that describe the purposes, require the
          recipient to keep the personal information confidential, and prohibit using the
          disclosed information for any purpose except performing the contract. In the
          preceding twelve (12) months, Company has not disclosed personal information
          for a business purpose to the categories of third parties indicated in the
          chart below.</p>

        <p className="Paragraph">We may also share your personal information by selling it to
          third parties, subject to your right to opt-out of those sales. Our personal
          information sales do not include information about individuals we know are
          under age 16. In the preceding twelve (12) months, Company has not sold
          personal information. For more on your personal information sale rights, see
          Personal Information Sales Opt-Out and Opt-In Rights.</p>

        <p className="Paragraph">&nbsp;</p>

        <table className="MsoNormalTable" border="0" cellSpacing="0" cellPadding="0" width="100%" style={{width:'100.0%',borderCollapse:'collapse'}}>
          <tbody><tr style={{height:'18.4pt'}}>
            <td width="28%" rowSpan="2" valign="top" style={{width:'28.82%',border:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt',height:'18.4pt'}}>
              <p className="Paragraph" align="center" style={{textAlign:'center'}}><b>Personal
                                                                                       Information Category</b></p>
            </td>
            <td width="71%" colSpan="2" valign="top" style={{width:'71.18%',border:'solid windowtext 1.0pt',borderLeft:'none',padding:'0in 5.75pt 8.65pt 5.75pt',height:'18.4pt'}}>
              <p className="Paragraph" align="center" style={{textAlign:'center'}}><b>Category of
                    Third-Party Recipients</b></p>
            </td>
          </tr>
          <tr>
            <td width="36%" valign="top" style={{width:'36.6%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph" align="center" style={{textAlign:'center'}}><b>Business Purpose
                          Disclosures</b></p>
            </td>
            <td width="34%" valign="top" style={{width:'34.58%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph" align="center" style={{textAlign:'center'}}><b>Sales</b></p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top" style={{width:'28.82%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">A: Identifiers.</p>
            </td>
            <td width="36%" valign="top" style={{width:'36.6%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
            <td width="34%" valign="top" style={{width:'34.58%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top" style={{width:'28.82%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">B: California Customer Records personal information
                categories.</p>
            </td>
            <td width="36%" valign="top" style={{width:'36.6%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
            <td width="34%" valign="top" style={{width:'34.58%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top" style={{width:'28.82%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">C: Protected classification characteristics under
                California or federal law.</p>
            </td>
            <td width="36%" valign="top" style={{width:'36.6%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
            <td width="34%" valign="top" style={{width:'34.58%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top" style={{width:'28.82%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">D: Commercial information.</p>
            </td>
            <td width="36%" valign="top" style={{width:'36.6%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
            <td width="34%" valign="top" style={{width:'34.58%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top" style={{width:'28.82%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">E: Biometric information.</p>
            </td>
            <td width="36%" valign="top" style={{width:'36.6%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
            <td width="34%" valign="top" style={{width:'34.58%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top" style={{width:'28.82%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.75pt 8.65pt 5.75pt'}}>"" <p className="Paragraph">F: Internet or other similar network activity.</p>
            </td>
            <td width="36%" valign="top" style={{width:'36.6%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
            <td width="34%" valign="top" style={{width:'34.58%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top" style={{width:'28.82%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">G: Geolocation data.</p>
            </td>
            <td width="36%" valign="top" style={{width:'36.6%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
            <td width="34%" valign="top" style={{width:'34.58%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top" style={{width:'28.82%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">H: Sensory data.</p>
            </td>
            <td width="36%" valign="top" style={{width:'36.6%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
            <td width="34%" valign="top" style={{width:'34.58%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top" style={{width:'28.82%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">I: Professional or employment-related information.</p>
            </td>
            <td width="36%" valign="top" style={{width:'36.6%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
            <td width="34%" valign="top" style={{width:'34.58%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top" style={{width:'28.82%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">J: Non-public education information.</p>
            </td>
            <td width="36%" valign="top" style={{width:'36.6%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
            <td width="34%" valign="top" style={{width:'34.58%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top" style={{width:'28.82%',border:'solid windowtext 1.0pt',borderTop:'none',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">K: Inferences drawn from other personal information.</p>
            </td>
            <td width="36%" valign="top" style={{width:'36.6%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
            <td width="34%" valign="top" style={{width:'34.58%',borderTop:'none',borderLeft:'none',borderBottom:'solid windowtext 1.0pt',borderRight:'solid windowtext 1.0pt',padding:'0in 5.75pt 8.65pt 5.75pt'}}>
              <p className="Paragraph">None</p>
            </td>
    </tr>
    </tbody>
        </table>

        <p className="CustomizableHeading" align="left" style={{textAlign:'left'}}><a id="a443678"> </a><i>Reselling Personal Information</i></p>

        <p className="Paragraph">The CCPA prohibits a third party from reselling personal information
          unless you have received explicit notice and an opportunity to opt-out of
          further sales. </p>

        <p className="CustomizableHeading"><a id="a363591"> </a><u>Your Rights and Choices </u></p>

        <p className="Paragraph">The CCPA provides consumers (California residents) with
          specific rights regarding their personal information. This section describes
          your CCPA rights and explains how to exercise those rights.</p>

        <p className="CustomizableHeading" align="left" style={{textAlign:'left'}}><a id="a828717"> </a><i>Right to Know and Data Portability</i></p>

        <p className="Paragraph">You have the right to request that we disclose certain
          information to you about our collection and use of your personal information
          over the past 12 months (the “<b>right to know</b>”). Once we receive your
          request and confirm your identity (see Exercising Your Rights to Know or
          Delete), we will disclose to you:</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>The categories of
          personal information we collected about you.</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>The categories of
          sources for the personal information we collected about you.</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Our business or
          commercial purpose for collecting or selling that personal information.</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>The categories of
          third parties with whom we share that personal information.</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>If we sold or
          disclosed your personal information for a business purpose, two separate lists
          disclosing:</p>

        <p className="BulletList2"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>sales,
          identifying the personal information categories that each category of recipient
          purchased; and </p>

        <p className="BulletList2"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>disclosures for a
          business purpose, identifying the personal information categories that each
          category of recipient obtained. </p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>The specific
          pieces of personal information we collected about you (also called a data
          portability request).</p>

        <p className="CustomizableHeading" align="left" style={{textAlign:'left'}}><a id="a664478"> </a><i>Right to Delete </i></p>

        <p className="Paragraph">You have the right to request that we delete any of your
          personal information that we collected from you and retained, subject to
          certain exceptions (the “<b>right to delete</b>”). Once we receive your request
          and confirm your identity (see Exercising Your Rights to Know or Delete), we
          will review your request to see if an exception allowing us to retain the
          information applies. We may deny your deletion request if retaining the
          information is necessary for us or our service provider(s) to: </p>

        <p className="List-NumberedListLevel1">1.&nbsp; <span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;
                                                                                     </span>Complete the transaction for which we collected the personal
          information, provide a good or service that you requested, take actions
          reasonably anticipated within the context of our ongoing business relationship
          with you, fulfill the terms of a written warranty or product recall conducted
          in accordance with federal law, or otherwise perform our contract with you.</p>

        <p className="List-NumberedListLevel1">2.&nbsp; <span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;
                            </span>Detect security incidents, protect against malicious, deceptive,
          fraudulent, or illegal activity, or prosecute those responsible for such
          activities.</p>

        <p className="List-NumberedListLevel1">3.&nbsp; <span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;
                                                        </span>Debug products to identify and repair errors that impair existing
          intended functionality.</p>

        <p className="List-NumberedListLevel1">4.&nbsp; <span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;
           </span>Exercise free speech, ensure the right of another consumer to exercise
          their free speech rights, or exercise another right provided for by law.</p>

        <p className="List-NumberedListLevel1">5.&nbsp; <span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;
                                                   </span>Comply with the California Electronic Communications Privacy Act (Cal.
          Penal Code § 1546 <i>et. seq.</i>).</p>

        <p className="List-NumberedListLevel1">6.&nbsp; <span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;
                     </span>Engage in public or peer-reviewed scientific, historical, or statistical
          research in the public interest that adheres to all other applicable ethics and
          privacy laws, when the information's deletion may likely render impossible or
          seriously impair the research's achievement, if you previously provided
          informed consent.</p>

        <p className="List-NumberedListLevel1">7.&nbsp; <span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;
                                                                             </span>Enable solely internal uses that are reasonably aligned with consumer
          expectations based on your relationship with us.</p>

        <p className="List-NumberedListLevel1">8.&nbsp; <span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;
                                                      </span>Comply with a legal obligation.</p>

        <p className="List-NumberedListLevel1">9.&nbsp; <span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;
                                </span>Make other internal and lawful uses of that information that are
          compatible with the context in which you provided it.</p>

        <p className="Paragraph">We will delete or deidentify personal information not
          subject to one of these exceptions from our records and will direct our service
          providers to take similar action. </p>

        <p className="CustomizableHeading" align="left" style={{textAlign:'left'}}><a id="a585939"> </a><i>Exercising Your Rights to Know or Delete</i></p>

        <p className="Paragraph">To exercise your rights to know or delete described above,
          please submit a request by: </p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Emailing us at
          info@youtip.money.</p>

        <p className="Paragraph">Only you, or someone legally authorized to act on your
          behalf, may make a request to know or delete related to your personal
          information. </p>

        <p className="Paragraph">You may also make a request to know or delete on behalf of
          your child by emailing us at info@youtip.money. </p>

        <p className="Paragraph">You may only submit a request to know twice within a
          12-month period. Your request to know or delete must:</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Provide
          sufficient information that allows us to reasonably verify you are the person
          about whom we collected personal information or an authorized representative,
          which may include:</p>

        <p className="BulletList2"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Your name, date
          of transaction(s), and email address.</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Describe your
          request with sufficient detail that allows us to properly understand, evaluate,
          and respond to it.</p>

        <p className="Paragraph">We cannot respond to your request or provide you with
          personal information if we cannot verify your identity or authority to make the
          request and confirm the personal information relates to you. </p>

        <p className="Paragraph">You do not need to create an account with us to submit a
          request to know or delete. </p>

        <p className="Paragraph">We will only use personal information provided in the
          request to verify the requestor’s identity or authority to make it. </p>

        <p className="Paragraph">For instructions on exercising your sale opt-out or opt-in
          rights, see Personal Information Sales Opt-Out and Opt-In Rights.</p>

        <p className="CustomizableHeading" align="left" style={{textAlign:'left'}}><a id="a736729"> </a><i>Response Timing and Format</i></p>

        <p className="Paragraph">We will confirm receipt of your request within ten (10)
          business days. If you do not receive confirmation within the 10-day timeframe,
          please email us at info@youtip.money.</p>

        <p className="Paragraph">We endeavor to substantively respond to a verifiable
          consumer request within forty-five (45) days of its receipt. If we require more
          time (up to another 45 days), we will inform you of the reason and extension
          period in writing.</p>

        <p className="Paragraph">We will deliver our written response by mail or
          electronically, at your option. </p>

        <p className="Paragraph">Any disclosures we provide will only cover the 12-month
          period preceding our receipt of your request. The response we provide will also
          explain the reasons we cannot comply with a request, if applicable. For data
          portability requests, we will select a format to provide your personal
          information that is readily useable and should allow you to transmit the
          information from one entity to another entity without hindrance.</p>

        <p className="Paragraph">We do not charge a fee to process or respond to your
          verifiable consumer request unless it is excessive, repetitive, or manifestly
          unfounded. If we determine that the request warrants a fee, we will tell you
          why we made that decision and provide you with a cost estimate before
          completing your request. </p>

        <p className="CustomizableHeading" align="left" style={{textAlign:'left'}}><a id="a794300"> </a><i>Personal Information Sales Opt-Out and Opt-In Rights</i></p>

        <p className="Paragraph">If you are age 16 or older, you have the right to direct us
          to not sell your personal information at any time (the “<b>right to opt-out</b>”).
          We do not sell the personal information of consumers. </p>

        <p className="CustomizableHeading"><a id="a130584"> </a><u>Non-Discrimination</u></p>

        <p className="Paragraph">We will not discriminate against you for exercising any of
          your CCPA rights. Unless permitted by the CCPA, we will not:</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Deny you
          services.</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Charge you
          different prices or rates for services, including through granting discounts or
          other benefits, or imposing penalties.</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Provide you a
          different level or quality of services.</p>

        <p className="BulletList1"><span style={{fontFamily:'Symbol'}}>·</span><span style={{fontSize:'7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Suggest that you
          may receive a different price or rate for services or a different level or
          quality of services.</p>

        <p className="Paragraph">However, we may offer you certain financial incentives
          permitted by the CCPA that <b>can result </b>in different prices, rates, or
          quality levels. Any CCPA-permitted financial incentive we offer will reasonably
          relate to your personal information’s value and contain written terms that
          describe the program's material aspects. Participation in a financial incentive
          program requires your prior opt-in consent, which you may revoke at any time.
          &nbsp;</p>

        <p className="CustomizableHeading"><a id="a545195"> </a><u>Changes to Our Privacy Policy</u></p>

        <p className="Paragraph">We reserve the right to amend this Privacy Policy at our
          discretion and at any time. When we make changes to this Privacy Policy, we
          will post the updated notice on the Website and update the notice’s effective
          date. <b>Your continued use of our Website following the posting of changes
                                                 constitutes your acceptance of such changes</b>.</p>

        <p className="CustomizableHeading"><a id="a953370"> </a><u>Contact Information</u></p>

        <p className="Paragraph">If you have any questions or comments about this notice, the
          ways in which we collect and use your information described here and in the
          Privacy Policy<a id="_msoanchor_2"> </a><a href="#_msocom_2"><span style={{fontSize:'8.0pt'}}>[MM2]</span></a><span className="MsoCommentReference"><span style={{fontSize:'8.0pt'}}>&nbsp;</span></span>, your choices and rights regarding
          such use, or wish to exercise your rights under California law, please do not
          hesitate to contact us at:</p>

    <p className="MsoNormal" style={{marginTop:'0in'}}><b><span style={{fontFamily:'"Times New Roman",serif'}}>Website</span></b>: <a href="https://www.youtip.money/contact/">https://www.youtip.money/contact/</a></p>

        <p className="ParaFirst-lineIndent" style={{textIndent:'0in'}}><b>Email</b>:
          info@youtip.money</p>

        <p className="ParaFirst-lineIndent" style={{textIndent:'0in'}}><b>Postal Address</b>: </p>

        <p className="ParaFirst-lineIndent" style={{marginTop:'0in'}}><span style={{color:'windowtext'}}>youtip
                                                                   Inc.</span></p>

        <p className="ParaFirst-lineIndent" style={{marginTop:'0in'}}><span style={{color:'windowtext'}}>Attn:
                     Customer Support</span></p>

        <p className="MsoNormal" style={{marginTop:'0in',textIndent:'.5in'}}><span style={{fontFamily:'"Times New Roman",serif',letterSpacing:'-.15pt',background:'white'}}>1440 W. Taylor Street</span><span style={{fontFamily:'"Times New Roman",serif',color:'windowtext'}}> </span><span style={{fontFamily:'"Times New Roman",serif',letterSpacing:'-.15pt',background:'white'}}>#488 </span></p>

        <p className="MsoNormal" style={{marginTop:'0in',textIndent:'.5in'}}><span style={{fontFamily:'"Times New Roman",serif',letterSpacing:'-.15pt',background:'white'}}>Chicago, IL 60607</span></p>

        <p className="Paragraph">If you need to access this Policy in an alternative format due to having a disability, please contact info@youtip.money.</p>
        <div>
        </div>
      </div>
    </div>
  );
};

export default CaliPrivacyPolicy;
