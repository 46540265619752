import React, { Component } from 'react';
import {
  Dimmer,
  Grid,
  Loader,
  Modal,
} from 'semantic-ui-react';

import AppHeader from './AppHeader';
import PaymentContainer from './PaymentContainer';
import TipContainer from './TipContainer';
import Receipt from './Receipt';
import { YouTipContext } from '../YouTipContextProvider';

import NotFound from '../NotFound';
import TermsOfUse from '../utils/TermsOfUse';
import PrivacyPolicy from '../utils/PrivacyPolicy';
import CaliPrivacyPolicy from '../utils/CaliPrivacyPolicy';

const TermsAndConditionsModal = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <Modal size='mini'
           closeIcon
           centered={false}
           open={open}
           onClose={() => setOpen(false)}
           onOpen={() => setOpen(true)}
           trigger={
             <div style={{ textAlign: 'center', padding: '0.25rem 0 0.75rem 0', lineHeight:'1.2rem' }}>
                   <a href='#tc'id='terms-and-conditions-button'
                      className='terms-and-conditions-link'
                      style={{ cursor: 'pointer' }}
                   >
                     By clicking any of the pay buttons below, you agree to the <u>Terms of Use and Privacy Policy</u>.
                   </a>
                 </div>
           }
    >
      <Modal.Header>Terms of Use & Privacy Policy</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description style={{ fontSize: '0.85em', lineHeight: '1em', color: '#333' }}>
          <TermsOfUse />
          <PrivacyPolicy />
          <CaliPrivacyPolicy />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

class InstanceContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { loading: true, paymentComplete: false };

    this.loadInstance = this.loadInstance.bind(this);
    this.handleTipDataChange = this.handleTipDataChange.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
  }

  componentDidMount() {
    this.loadInstance();
  }

  async loadInstance() {
    const { currentInstance, lockUi, unlockUi, setCurrentInstance, whenReady } = this.context;
    if (currentInstance) return;

    const { match: { params: { slug } } } = this.props;
    lockUi();
    whenReady().then(() => {
      setCurrentInstance(slug).then(() => {
        unlockUi();
      });
    });
  }

  handleTipDataChange({ tip, fee, total, valid }) {
    // this.setState({ tip, fee, total, tipValid: valid });
  }

  handlePayment(paymentData) {
    const { error, paymentIntent, paymentMethod, paymentRequest, card, ...data } = paymentData;
    if (error) {
      console.warn('-> [error] handling payment events ', error);
    } else if (paymentIntent.status === 'succeeded') {
      console.debug(`=> successfully processed payment. submitted data: `, paymentData);
      this.setState({ paymentComplete: true, paymentData });
    } else {
      console.warn('-> [] no error (nor .success field) found on payment data... ');
    }
  }

  renderPaymentContainer() {
    const { currentInstance } = this.context;

    return <PaymentContainer
             key={1337}
             description={currentInstance.displayName}
             metadata={{ instanceId: currentInstance.id }}
             onPayment={this.handlePayment}
           />;
  }

  renderDimmer() {
    const { uiDisabled } = this.context;

    return <React.Fragment>
             <AppHeader />
             <Grid.Row centered style={{paddingBottom: '2rem'}}>
               <Grid.Column>
                 {uiDisabled ? <Dimmer key='dimmer-1' active inverted><Loader size='large' /></Dimmer> : <NotFound />}
               </Grid.Column>
             </Grid.Row>
           </React.Fragment>;
  }

  render() {
    const {
      uiDisabled,
      currentInstance,
      transaction,
      paymentData
    } = this.context;

    if (!currentInstance || !currentInstance.uuid) {
      return (<React.Fragment>
                <AppHeader />
                <Grid.Row centered style={{paddingBottom: '2rem'}}>
                  <Grid.Column>
                    {uiDisabled ? <Dimmer key='dimmer-1' active inverted><Loader size='large' /></Dimmer> : <NotFound />}
                  </Grid.Column>
                </Grid.Row>
              </React.Fragment>);
    }

    const description = currentInstance.displayName;
    const theme = currentInstance.theme;

    if (document.title !== currentInstance.displayName)
      document.title = `youtip ${currentInstance.displayName}`;

    return (<React.Fragment>
              <AppHeader theme={theme} />
              <Grid.Row centered style={{paddingBottom: '2rem'}}>
                <Grid.Column>
                  {transaction.completed
                   ? <Receipt key='receipt' paymentData={paymentData} tipData={transaction} />
                   : <React.Fragment>
                       <TipContainer key='tipinput-1' isLoading={uiDisabled} />

                       <Grid.Row centered style={{padding: '0.3rem 0 0 0'}}>
                         <Grid.Column>
                           <TermsAndConditionsModal />
                         </Grid.Column>
                       </Grid.Row>

                       {this.renderPaymentContainer()}

                       {currentInstance.theme &&
                        <Grid.Row centered>
                          <Grid.Column style={{borderTop: '1px solid #ccc',paddingTop:'0.75rem',textAlign:'center',marginTop:'0.6rem'}}>
                            <img src="powered-by-youtip.png" height="30" align="center" />
                          </Grid.Column>
                        </Grid.Row>
                       }
                     </React.Fragment>}
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>);
  }
}

InstanceContainer.contextType = YouTipContext;

export default InstanceContainer;
