// export const API_URL = process.env.API_URL || 'localhost:8080';

// // TODO: deprecate this api env var, not sure it's being used
// export const API_SERVER = process.env.NODE_ENV === 'production'
//   ? 'https://api.youtip.pro'
//   : 'http://localhost:8080';

module.exports = {
  // API_SERVER,
  API_URL: process.env.API_URL || 'localhost:8080',
  MINIMUM_TIP: process.env.REACT_APP_MINIMUM_TIP || 100,
  STRIPE_ENV: process.env.REACT_APP_STRIPE_ENV,
  STRIPE_PUB_KEY: process.env.REACT_APP_STRIPE_PUB_KEY,
  STRIPE_ALT_PUB_KEY: process.env.REACT_APP_STRIPE_ALT_PUB_KEY,
  HQ_URL: process.env.REACT_APP_HQ_URL || 'localhost:8888'
};
