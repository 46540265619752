import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div>
      <div id="privacy-policy">
        <div className="c19"><a id="id.gjdgxs"> </a>
          <div className="c14"><h3>Privacy Policy</h3></div>
          <p className="c5"><span className="c0">Last modified: January 29, 2021</span></p>
          <p className="c5"><span className="c0">youtip, Inc. (&ldquo;</span><span className="c1">Company</span><span className="c0">&rdquo; or</span><span className="c1">&nbsp;</span><span className="c0">&ldquo;</span><span className="c1">We</span><span className="c0">&rdquo;) respect your privacy and are committed to protecting it through our compliance with this policy.</span></p>
          <p className="c5"><span className="c0">This policy describes the types of information we may collect from you or that you may provide when you visit </span><span className="c0"><a className="c9" href="https://www.google.com/url?q=http://www.youtip.money&amp;sa=D&amp;source=editors&amp;ust=1612736358591000&amp;usg=AOvVaw27NAfbstTNpoFFwIxuR65M">www.youtip.money</a></span><span className="c0">, www.youtip.pro, and any user interface, content, functionality, and services offered on or through these domains or by us (collectively, the &ldquo;</span><span className="c1">Website&rdquo;</span><span className="c0">) and our practices for collecting, using, maintaining, protecting and disclosing that information.</span></p>
          <p className="c5"><span className="c0">This policy applies to information we collect:</span></p>
          <ul className="c7 lst-kix_list_19-0 start">
            <li className="c2 li-bullet-0"><span className="c0">On this Website.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">In email, text, and other electronic messages between you and this Website.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">Through user interfaces or mobile and desktop applications you download from this Website, which provide dedicated non-browser-based interaction between you and this Website.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">When you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this policy.</span></li>
          </ul>
          <p className="c5"><span className="c0">It does not apply to information collected by:</span></p>
          <ul className="c7 lst-kix_list_19-0">
            <li className="c2 li-bullet-0"><span className="c0">Us offline or through any other means, including on any other website operated by Company or any third party; or </span></li>
            <li className="c2 li-bullet-0"><span className="c0">Any third party, including through any application or content (including advertising) that may link to or be accessible from or on the Website.</span></li>
          </ul>
          <p className="c5"><span className="c0">Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website. By accessing or using this Website, you agree to this Privacy Policy. This Policy may change from time to time as described herein. Your continued use of this Website after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates. </span><a id="id.30j0zll"> </a></p>
          <p className="c5"><span className="c6">Children Under the Age of 16</span></p>
          <p className="c5"><span className="c0">Our Website is not intended for children under 16 years of age. No one under age 16 may provide any personal information to or on the Website. We do not knowingly collect personal information from children under 16. If you are under 16, do not use or provide any information on this Website or through any of its features, submit any tips through the Website, or provide any information about yourself to us, including your name, address, telephone number, or email address. If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us at info@youtip.money.</span></p>
          <p className="c5"><span className="c0">California residents under 16 years of age may have additional rights regarding the collection and sale of their personal information. Please see </span><span className="c1"></span><a href="#cali-privacy-policy" id="cmnt_ref1">Your California Privacy Rights</a><span className="c0">&nbsp;for more information.</span><a id="id.1fob9te"> </a></p>
          <p className="c5"><span className="c6">Information We Collect About You and How We Collect It</span></p>
          <p className="c5"><span className="c0">We collect several types of information from and about users of our Website, including information:</span></p>
          <ul className="c7 lst-kix_list_19-0">
            <li className="c2 li-bullet-0"><span className="c0">By which you may be personally identified, such as name, e-mail address, and telephone number (&quot;</span><span className="c1">personal information</span><span className="c0">&quot;);</span></li>
            <li className="c2 li-bullet-0"><span className="c0">That is about you but individually does not identify you, such as your employer or industry; and/or</span></li>
            <li className="c2 li-bullet-0"><span className="c0">About your internet connection, the equipment you use to access our Website, and usage details.</span></li>
          </ul>
          <p className="c5"><span className="c0">We collect this information:</span></p>
          <ul className="c7 lst-kix_list_19-0">
            <li className="c2 li-bullet-0"><span className="c0">Directly from you when you provide it to us.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">From third parties, for example, our business partners.</span><a id="id.3znysh7"> </a></li>
          </ul>
          <p className="c5"><span className="c11">Information You Provide to Us</span><span className="c1">&nbsp; </span></p>
          <p className="c5"><span className="c0">The information we collect on or through our Website may include:</span></p>
          <ul className="c7 lst-kix_list_19-0">
            <li className="c2 li-bullet-0"><span className="c0">Information that you provide by filling in forms on our Website. This includes information provided at the time of making a tip using our service, providing Feedback material, or requesting information about our services. We may also ask you for information when you report a problem with our Website.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">Records and copies of your correspondence (including email addresses), if you contact us.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">Your responses to requests for feedback.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">Details of transactions you carry out through our Website. You may be required to provide financial information when making a tip using our Website.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">Your search queries on the Website.</span></li>
          </ul>
          <p className="c5"><span className="c0">You also may provide information to be transmitted to other users of the Website or third parties, such as the star rating and comments you provide when you submit tips (&ldquo;</span><span className="c1">User Contributions</span><span className="c0">&rdquo;). Your User Contributions are posted on and transmitted to others at your own risk. Although we limit access to certain pages, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users of the Website with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.</span><a id="id.2et92p0"> </a></p>
          <p className="c5"><span className="c11">Information We Collect Through Automatic Data Collection Technologies</span><span className="c13">&nbsp; </span></p>
          <p className="c5"><span className="c0">As you navigate through and interact with our Website, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</span></p>
          <ul className="c7 lst-kix_list_19-0">
            <li className="c2 li-bullet-0"><span className="c0">&nbsp;Details of your visits to our Website, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Website.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">Information about your computer and internet connection, including your IP address, operating system, and browser type.</span></li>
          </ul>
          <p className="c5"><span className="c0">The information we collect automatically may include statistical data and not personal information. It helps us to improve our Website and to deliver a better and more personalized service, including by enabling us to:</span></p>
          <ul className="c7 lst-kix_list_19-0">
            <li className="c2 li-bullet-0"><span className="c0">Estimate our audience size and usage patterns.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">Store information about your preferences, allowing us to customize our Website according to your individual interests.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">Speed up your searches.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">Recognize you when you return to our Website.</span></li>
          </ul>
          <p className="c5"><span className="c0">The technologies we use for this automatic data collection may include:</span></p>
          <ul className="c7 lst-kix_list_19-0">
            <li className="c2 li-bullet-0"><span className="c1">Cookies (or browser cookies).</span><span className="c0">&nbsp;A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website. </span></li>
            <li className="c2 li-bullet-0"><span className="c1">Flash Cookies.</span><span className="c0">&nbsp;Certain features of our Website may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, see </span><span className="c0"><a className="c9" href="#id.4d34og8">Choices About How We Use and Disclose Your Information</a></span><span className="c0">.</span></li>
            <li className="c2 li-bullet-0"><span className="c1">Web Beacons.</span><span className="c0">&nbsp;Pages of our Website and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity). </span><a id="id.tyjcwt"> </a></li>
          </ul>
          <p className="c5"><span className="c6">Third-Party Use of Cookies and Other Tracking Technologies</span></p>
          <p className="c5"><span className="c0">Some content or applications, including advertisements, on the Website may be served by third parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our website. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content. </span></p>
          <p className="c5"><span className="c0">We do not control these third parties&#39; tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see Choices About How We Use and Disclose Your Information.</span><a id="id.3dy6vkm"> </a></p>
          <p className="c5"><span className="c6">How We Use Your Information</span></p>
          <p className="c5"><span className="c0">We use information that we collect about you or that you provide to us, including any personal information:</span></p>
          <ul className="c7 lst-kix_list_19-0">
            <li className="c2 li-bullet-0"><span className="c0">To present our Website and its contents to you.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">To provide you with information or services that you request from us.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">To fulfill any other purpose for which you provide it.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">To provide you with information about your tips or feedback you provide.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">To carry out our obligations and enforce our rights arising.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">To notify you about changes to our Website or any services we offer or provide though it.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">To allow you to participate in interactive features on our Website.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">In any other way we may describe when you provide the information.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">For any other purpose with your consent.</span></li>
          </ul>
          <p className="c5"><span className="c0">We may also use your information to contact you about our own and third parties&rsquo; goods and services that may be of interest to you. If you do not want us to use your information in this way, please email us at info@youtip.money. For more information, see Choices About How We Use and Disclose Your Information.</span></p>
          <p className="c5"><span className="c0">We may use the information we have collected from you to enable us to display advertisements to our advertisers&rsquo; target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.</span><a id="id.1t3h5sf"> </a></p>
          <p className="c5"><span className="c6">Disclosure of Your Information</span></p>
          <p className="c5"><span className="c0">We may disclose aggregated information about our users, and information that does not identify any individual, without restriction. </span></p>
          <p className="c5"><span className="c0">We may disclose personal information that we collect, or you provide as described in this Privacy Policy:</span></p>
          <ul className="c7 lst-kix_list_19-0">
            <li className="c2 li-bullet-0"><span className="c0">To our company clients, contractors, service providers, and other third parties we use to support our business.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of the Company&rsquo;s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by the Company about our Website users is among the assets transferred.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">To third parties to market their products or services to you if you have not opted out of these disclosures. For more information, see Choices About How We Use and Disclose Your Information.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">To fulfill the purpose for which you provide it. </span></li>
            <li className="c2 li-bullet-0"><span className="c0">For any other purpose disclosed by us when you provide the information.</span></li>
            <li className="c2 li-bullet-0"><span className="c0">With your consent.</span></li>
          </ul>
        </div>
      </div>
      <p className="c5"><span className="c0">We may also disclose your personal information:</span></p>
      <ul className="c7 lst-kix_list_19-0">
        <li className="c2 li-bullet-0"><span className="c0">To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</span></li>
        <li className="c2 li-bullet-0"><span className="c0">To enforce or apply our </span><span className="c0 c3"></span><a href="#terms-of-use" id="cmnt_ref2">Terms of Use</a><span className="c0">&nbsp;and other agreements, including for billing and collection purposes.</span></li>
        <li className="c2 li-bullet-0"><span className="c0">If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our company clients, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</span><a id="id.4d34og8"> </a></li>
      </ul>
      <p className="c5"><span className="c6">Choices About How We Use and Disclose Your Information</span></p>
      <p className="c5"><span className="c0">We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information: </span></p>
      <ul className="c7 lst-kix_list_19-0">
        <li className="c2 li-bullet-0"><span className="c1">Tracking Technologies and Advertising.</span><span className="c0">&nbsp;You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe&rsquo;s website. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.</span></li>
        <li className="c2 li-bullet-0"><span className="c1">Disclosure of Your Information for Third-Party Advertising.</span><span className="c0">&nbsp;If you do not want us to share your personal information with unaffiliated or non-agent third parties for promotional purposes, you can opt-out by sending us an email stating your request to info@youtip.money.</span></li>
        <li className="c2 li-bullet-0"><span className="c1">Promotional Offers from the Company.</span><span className="c0">&nbsp;If you do not wish to have your contact information used by the Company to promote our own or third parties&rsquo; products or services, you can opt-out by sending us an email stating your request to info@youtip.money. If we have sent you a promotional email, you may send us a return email asking to be omitted from future email distributions. This opt-out does not apply to information provided to the Company as a result of a service experience or transactions.</span></li>
        <li className="c2 li-bullet-0"><span className="c1">Targeted Advertising.</span><span className="c0">&nbsp;If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers&rsquo; target-audience preferences, you can opt-out by emailing a request to info@youtip.money. For this opt-out to function, you must have your browser set to accept all browser cookies. </span></li>
      </ul>
      <p className="c5"><span className="c0">We do not control third parties&rsquo; collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (&ldquo;</span><span className="c1">NAI</span><span className="c0">&rdquo;) on the NAI&rsquo;s website.</span></p>
      <p className="c5"><span className="c0">California residents may have additional personal information rights and choices. Please see </span><span className="c0"></span><a href="#cali-privacy-policy" id="cmnt_ref3">Your California Privacy Rights</a><span className="c0">&nbsp;for more information.</span></p>
      <p className="c5"><span className="c0">Nevada residents who wish to exercise their sale opt-out rights under Nevada Revised Statutes Chapter 603A may submit a request to this designated address: info@youtip.money.</span><a id="id.2s8eyo1"> </a></p>
      <p className="c5"><span className="c6">Accessing and Correcting Your Information</span></p>
      <p className="c5"><span className="c0">You may send us an email at info@youtip.money to request access to, correct or delete any personal information that you have provided to us. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</span></p>
      <p className="c5"><span className="c0">California residents may have additional personal information rights and choices. Please see Your California Privacy Rights for more information.</span><a id="id.17dp8vu"> </a></p>
      <p className="c5"><span className="c6">Your California Privacy Rights</span></p>
      <p className="c5"><span className="c0">If you are a California resident, California law may provide you with additional rights regarding our use of your personal information. See </span><span className="c0"></span><a href="#cali-privacy-policy" id="cmnt_ref4">Privacy Policy</a><span className="c0">&nbsp;for California Residents.</span></p>
      <p className="c5"><span className="c0">California&rsquo;s &ldquo;Shine the Light&rdquo; law (Civil Code Section &sect; 1798.83) permits users of our Website that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to info@youtip.money.</span><a id="id.3rdcrjn"> </a></p>
      <p className="c5"><span className="c6">Data Security</span></p>
      <p className="c5"><span className="c0">We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored securely. Any payment transactions will be encrypted using SSL technology. </span></p>
      <p className="c5"><span className="c0">The safety and security of your information also depends on you. The transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website. </span><a id="id.26in1rg"> </a></p>
      <p className="c5"><span className="c6">Changes to Our Privacy Policy</span></p>
      <p className="c5"><span className="c0">It is our policy to post any changes we make to our Privacy Policy on this page. If we make material changes to how we treat our users&rsquo; personal information, we will notify you through a notice on the Website home page. The date the Privacy Policy was last revised is identified at the top of the Policy. You are responsible for periodically visiting our Website and this Privacy Policy to check for any changes.</span><a id="id.lnxbz9"> </a></p>
      <p className="c5"><span className="c6">Contact Information</span></p>
      <p className="c5"><span className="c0">To ask questions or comment about this Privacy Policy and our privacy practices, contact us at: info@youtip.money. </span></p>
      <p className="c4"><span className="c0"></span></p>
      <div>
        <p className="c16"><span className="c0"></span></p>
        <p className="c5"><span className="c17">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
      </div>
    </div>
      );
};

export default PrivacyPolicy;
