import React from 'react';
import { Icon, Segment } from 'semantic-ui-react';

const NotFound = (props) => {
  return (
    <Segment raised id='404-segment' textAlign='center'>
      <Icon name='question circle' size='huge' style={{ color: '#ddd' }} />
      <h3>This URL doesn't match anything in our records. :(</h3>
    </Segment>
  );
// return (<React.Fragment>
//                <AppHeader />
//                <Grid.Row centered style={{paddingBottom: '2rem'}}>
//                  <Grid.Column>
//                    <Segment raised id='404-segment' textAlign='center'>
//                      <br/><br/>
//                      <Icon name="question circle" size='huge' style={{color:'#ddd'}} />
//                      <h3>This URL doesn't match anything in our system. :(</h3>
//                      <br/>
//                    </Segment>
//                  </Grid.Column>
//                </Grid.Row>
//              </React.Fragment>);
};

  export default NotFound;
