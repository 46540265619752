import { isThisTypeNode } from 'typescript';
import ApiClient from './ApiClient';
import { MINIMUM_TIP } from './constants';

let __currentInstance;
let __currentInstanceId;

// hack. this should move into models and such. YouTip SDK of sorts
const YouTip = {
  currentInstance: () => {
    // console.log('=/ currentInstance() /=> ', __currentInstance);
    return __currentInstance;
  },

  Instance: class {
    constructor({
      id,
      uuid,
      info,
      name,
      service,
      slug,
      content = {},
      fees = [],
      minimum = MINIMUM_TIP,
      //currency,
      //locale,
      theme,
      description,
      group_name,
      metadata = {},
      ...otherData
    }) {
      this.id = id;
      this.uuid = uuid;
      this.slug = slug;
      Object.keys(content).forEach(k => {
        if (content[k] && (content[k].toLowerCase() === 'true' || content[k].toLowerCase() === 'false')) {
          content[k] = content[k].toLowerCase() === 'true';
        }
      });
      this.content = Object.assign({}, YouTip.defaultInstanceContent, content);
      this.fees = fees;
      this.minimum = this.content.minimum;
      this.theme = theme;
      this.recipient = { info, name, service };

      this.groupName = group_name;
      this.displayName = group_name?.length ? `${group_name}-${name}` : `${name}${info ? `-${info}` : ''}`;
      this.displayNameForCharge = name ? `${name.substr(0, 16)}` : '';

      this.name = name;
      this.info = info;
      this.service = service;
      this.reviewId = null;
      this._reviews = null;

      this.metadata = metadata;
      this.description = description;

      // this._ccSuffix =

      this.createdAt = new Date().toLocaleString();
      this.createdAtUtc = new Date().toUTCString();

      this.currency = this.content.currency;
      this.locale = this.content.locale;
      // this.feesAreCovered = this.content.feesAreCovered && this.content.feesAreCovered === 'true';// ? true : false;
      this.feesAreCovered = this.content.feesAreCovered;
      this.fees.forEach(f => f.adjustment_value = parseFloat(f.adjustment_value));

      this.content.optionalTipInput = content.optionalTipInput ? JSON.parse(content.optionalTipInput) : null;
      this.content.recipientList = content.recipientList ? JSON.parse(content.recipientList) : null;
      if (this.content.recipientList && !this.content.recipientList.metadataKey)
        this.content.recipientList.metadataKey = 'selectedRecipient';
    }

    getReceiptCTA = () => {
      //return this.content.receiptCTA ? JSON.parse(this.content.receiptCTA) : null;
      //let dumb = this.content.receiptCTA instanceof String ? JSON.parse(this.content.receiptCTA) : this.content.receiptCTA;
      //console.log('-> receiptCTA value:', dumb);
      return this.content.receiptCTA ? JSON.parse(this.content.receiptCTA) : null;
    }

    getPresetTips = () => {
      const { presetTips } = this.content;

      // console.debug(`-> preset tips raw from db: `, presetTips);
      const tipAmounts = presetTips.split(",").map(tipString => Number.parseInt(tipString));

      return tipAmounts;
      // return this.content.presetTips;
    }

    valid = () => {
      return this.id && this.content && this.fees && this.recipient;
    }

    checkConfiguredInputs = () => {
      const { optionalTipInput, recipientList } = this.content;
      //console.log(`--> checkConfiguredInputs...`, this.getMetadata(), recipientList);
      if (recipientList && !this.getMetadata()[recipientList.metadataKey])
        return false;

      if (!optionalTipInput) return true;

      const extraInputs = [optionalTipInput].flat();
      return extraInputs.every(input =>
        !input.required || this.getMetadata()[input.metadataKey]?.length
      );
      /*
            let missingValues = false;
            if (optionalTipInput) {
              const extraInputs = [optionalTipInput].flat(); // to support single or multi inputs config
              extraInputs.forEach(field => {
                // field is valid if it's optional or has a value
                if (field.required && !currentInstance.metadata[field.metadataKey]) {
                  missingValues = true;
                  return false;
                }
              });
            }   
      */
    }

    reviews = async () => {
      // if (!this._reviews) {
      //   const reviews = await ApiClient.fetchReviews(this.uuid)
      //         .then(list => { console.log(`-> fetched reviews data: `, list); return list; });

      //   this._reviews = reviews;
      // }

      // console.log(`-> reviews`, this._reviews);
      // return this._reviews;
    }

    // saveRating = async ({ })
    saveReview = async ({ stars, comments, paymentId }) => {
      if (!stars) {
        return { error: `review star-rating value is required`, stars };
      }

      let reviewData = { instanceUuid: this.uuid, stars, paymentId };
      if (comments)
        reviewData.comments = comments;
      if (this.reviewId)
        reviewData.reviewId = this.reviewId;

      const insertedReview = await ApiClient.createReview(reviewData);
      if (!this.reviewId)
        this.reviewId = insertedReview[0];

      // console.debug(`-> result of saveReview (id ${this.reviewId}): `, insertedReview);

      return insertedReview;
    }

    getMetadata = () => {
      return Object.assign(
        {},
        // { description: this.description },
        { ...this.metadata },
        {
          name: this.name,
          slug: this.slug,
          uuid: this.uuid,
          group: this.groupName,
          visitStartedAt: this.createdAtUtc,
        }
      );
    }

    setMetadata = (metadata = {}) => {
      const newMetadata = Object.assign(
        {},
        { ...this.metadata },
        { ...metadata }
      );

      this.metadata = newMetadata;
      return this.getMetadata();
    }
  },

  defaultInstanceContent: {
    commentLink: 'Leave a comment',
    receiptThankYou: 'Your tip has been sent and is greatly appreciated!',
    ratingThankYou: 'Thanks for rating!',
    ratingHeader: 'Rate the service',
    ratingMax: 5,
    ratingIcon: 'star',
    tipAmountHeader: 'Tip Amount',
    presetTips: "500,1000,2000",
    minimum: MINIMUM_TIP,
    currency: 'usd',
    locale: 'en-US',
    useStripeReceipt: true,
  },
};

/*

*/
YouTip.setCurrentInstance = async (slug) => {
  if (!slug || (YouTip.currentInstance() && slug === YouTip.currentInstance().slug))
    return null;

  // if (YouTip.currentInstance() && slug === YouTip.currentInstance().slug)
  //   return;

  const resp = await ApiClient.getInstanceBySlug(slug);
  if (!resp)
    return null;
  // return { error: `Unable to find instance for slug ${slug}!` };

  __currentInstance = new YouTip.Instance(resp);

  const theme = YouTip.currentInstance().theme;
  const cssUrl = theme ? `/themes/${theme}.css` : `/themes/default.css`;//`/App.css`;
  const style = document.createElement("link");
  style.href = cssUrl;
  style.rel = "stylesheet";
  style.async = true;

  document.head.appendChild(style);
  // __currentInstanceId = instance.uuid; // <--- deprecated (todo: confirm no refs)

  return YouTip.currentInstance();
};

YouTip.setCurrentInstanceById = async (instanceId) => {
  // console.log('==/ setCurrentInstance /=> instanceId provided: ', instanceId);
  if (!instanceId || instanceId === __currentInstanceId)
    return;

  // ref the id, load the data (or get cached data depending on ApiClient), ref the new instance
  __currentInstanceId = instanceId;
  const instanceData = await ApiClient.getInstanceById(instanceId);
  __currentInstance = new YouTip.Instance(instanceData);
};

export default YouTip;

// YouTip.defaultInstanceContent = {
//   commentLabel: 'Leave a comment'
// };

  // recipient is of format {} with structure:
  //   * group (the group owning this recipient)
  //   * name (name of the person or target (e.g. Marty McFly; simply 'Bartender'; Guest Room 1015; etc)
  //     service (additional descriptor e.g. _Housekeeping Service_)
  //     contact info or such?..

  // fees is of format [{}, {}, ...] with entries having structure:
  //   *** one (or both) field(s) required
  //     flat (static fee/value to add; NOTE: stored as dollars/decimal, not cents!)
  //     perc (percentage as decimal for calculating dynamic fee/value to add)

//};
