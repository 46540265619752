import React, { Component } from 'react';

import {
  Button,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Rating,
  Segment,
  Table
} from 'semantic-ui-react';

import RecipientInfo from './RecipientInfo.js';
import { YouTipContext } from '../YouTipContextProvider';
import ApiClient from '../ApiClient';

export default class Receipt extends Component {
  state = {
    ratingScore: 0,
    ratingComment: '',
    emailSent: false,
    emailAddress: '',
    emailError: false
  }

  toggleCommentBox = () => this.setState({ showCommentBox: !this.state.showCommentBox })

  handleCommentChange = (e, { name, value }) => {
    this.setState({ ratingComment: value });
  }

  handleRate = async (e, { rating, maxRating }) => {
    const { ratingScore } = this.state;
    const { currentInstance, payment } = this.context;
    const { paymentIntent } = payment;

    if (ratingScore !== null)
      await currentInstance.saveReview({
        stars: rating,
        paymentId: paymentIntent.id
      })
        .then(() => this.setState({ ratingScore: rating }));//, ratingSubmitted: true });
  }

  handleEmailChange = (e, { name, value }) => {
    //console.log(`[receipt handleEmailChange] new value for ${name} is ${value}`);
    this.setState({ emailAddress: value, emailSent: false });
  }

  sendEmailReceipt = async () => {
    const { emailAddress } = this.state;
    //if (emailSent || !emailAddress)
    if (!emailAddress)
      return null;

    //const message = await ApiClient.sendEmail(emailAddress)
    // const { payment, transaction, currentInstance, utils } = this.context;
    const { payment: { paymentIntent }, currentInstance } = this.context;
    const { useStripeReceipt, stripeAlt } = currentInstance.content;

    //const message = useStripeReceipt ? send stripe email : emailReceipt
    // const message = useStripeReceipt 
    //   ? await ApiClient.updateIntent({ receipt_email: emailAddress }) 
    //   : await ApiClient.emailReceipt({
    //     to: emailAddress,
    //     paymentId: paymentIntent.id
    //   });
    const message = await ApiClient.emailReceipt({
      to: emailAddress,
      paymentId: paymentIntent.id,
      useStripeReceipt,
      stripeAlt
    });
    if (message)
      this.setState({ emailSent: true, emailError: false, lastAddress: emailAddress, emailAddress: '' });
    else
      this.setState({ emailSent: false, emailError: `Email failed to send, check the address and try again.` });

    return message;
  }

  submitRatingAndComment = async () => {
    const { ratingScore, ratingComment } = this.state;
    const { currentInstance, payment } = this.context;
    const { paymentIntent } = payment;

    await currentInstance.saveReview({
      stars: ratingScore,
      comments: ratingComment.trim(),
      paymentId: paymentIntent.id
    })
      .then(() => this.setState({ ratingSubmitted: true }));
  }

  validEmail = () => {
    const { emailAddress } = this.state;
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    return pattern.test(emailAddress);
  }

  renderEmailInput = () => {
    const { emailAddress, emailSent, emailError, lastAddress } = this.state;
    const { currentInstance } = this.context;

    const innerContent = <React.Fragment>
      {emailSent && <p className='receipt-message'>{currentInstance.content.receiptEmailSent || `Receipt successfully emailed to ${lastAddress}`}</p>}
      <div className="receipt-email-box">
        <Input name='receipt-email-input'
          className='receipt-email-input'
          value={emailAddress}
          onChange={this.handleEmailChange}
          placeholder="Email Address"
          size='mini'
          fluid />
      </div>
      <Button className='receipt-email-submit'
        onClick={this.sendEmailReceipt}
        size='small'
        compact
        disabled={!this.validEmail()}
      >
        {currentInstance.content.receiptEmailHeader || `Send a copy of the receipt`}
      </Button>
      {emailError && <div style={{ fontSize: '11px', color: '#cc0000' }}>{emailError}</div>}
    </React.Fragment>;

    return (
      <Segment raised textAlign='center' className='receipt-content-segment email-receipt-segment'>
        {innerContent}
      </Segment>
    );
  }

  renderCommentBox = () => {
    const { ratingComment, ratingSubmitted } = this.state;
    const { currentInstance } = this.context;
    if (ratingSubmitted) {
      return (
        <div className='submitted-comment'>
          <Icon name='check circle' />
          {currentInstance.content.receiptCommentSent
            ? currentInstance.content.receiptCommentSent
            : `Note sent!`}
        </div>
      );
    }

    return (
      <React.Fragment>
        <Input name='comment-box'
          className='comment-box'
          value={ratingComment}
          onChange={this.handleCommentChange}
          placeholder={currentInstance.content.receiptCommentPlaceholder
            ? currentInstance.content.receiptCommentPlaceholder
            : `Leave a note with your tip. (optional)`}
          size='mini'
          fluid />

        <Button className='comment-box-submit'
          onClick={this.submitRatingAndComment}
          size='small'
          compact
        >
          Send it!
        </Button>
      </React.Fragment>
    );

    /*
    return showCommentBox ? (
      <React.Fragment>
        <Input name='comment-box'
               className='comment-box'
               value={ratingComment}
               onChange={this.handleCommentChange}
               placeholder=''
               size='mini'
               fluid />

        <Button className='comment-box-submit'
                onClick={this.submitRatingAndComment}
                size='small'
                compact
        >
          Send it
        </Button>
      </React.Fragment>
    ) : (
      <div>
        <button className='comment-box-link' onClick={this.toggleCommentBox}>
          {currentInstance.content.commentLink}
        </button>
      </div>
    );
    */
  }

  render() {
    const { payment, transaction, currentInstance, utils } = this.context;
    const { paymentIntent } = payment;
    const { currency, locale, feesAreCovered } = currentInstance;
    const { ratingScore, emailSent } = this.state;

    const tipDisplay = utils.centsToString(transaction.tip, {
      locale,
      currency
    });
    const feeDisplay = utils.centsToString(transaction.fee, {
      locale,
      currency
    });
    const totalDisplay = utils.centsToString(feesAreCovered ? transaction.tip : transaction.total, {
      locale,
      currency
    });

    const imgThanks = currentInstance.theme
      ? <Image src={`/themes/${currentInstance.theme}/receipt_thankyou.png`} height={65} centered />
      : <Image src='receipt_thankyou.png' height={65} centered />;

    const formattedDate = new Date(transaction.completedAt).toLocaleString();

    const receiptCTA = currentInstance.getReceiptCTA();

    let ratingAndFeedback = currentInstance.content.hideRatingAndFeedback
      ? null
      : <>
        <Header as='h5' centered='true' style={{ fontWeight: 'normal' }}>
          {ratingScore
            ? currentInstance.content.ratingThankYou
            : currentInstance.content.ratingHeader}
        </Header>
        {<Rating maxRating={currentInstance.content.ratingMax}
          onRate={this.handleRate}
          clearable
          size='huge' />}
        {this.renderCommentBox()}
      </>;

    let receiptCTAContainer = receiptCTA
      ? <Segment raised textAlign='center' className='receipt-content-segment'>
        <p id='receipt-cta-message'>
          {receiptCTA.message || currentInstance.content.receiptThankYou}
        </p>
        {(receiptCTA.buttonLink && receiptCTA.buttonText) &&
          <Button id='receipt-cta-button' fluid as='a' href={receiptCTA.buttonLink}>
            {receiptCTA.buttonText}
          </Button>}
      </Segment>
      : null;

    let ratingAndFeedbackContainer = <Segment raised textAlign='center' className='rate-service-segment'>
      <p id='receipt-message'>{currentInstance.content.receiptThankYou}</p>
      {ratingAndFeedback}
    </Segment>;

    let emailReceiptContent = currentInstance.content.receiptShowEmailInput
      ? this.renderEmailInput()
      : null;

    return (
      <Grid id='receipt-container' textAlign='center'>
        <Grid.Row className='receipt-image-row' textAlign='center'>
          <Grid.Column>
            {imgThanks}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='rate-service-row receipt-content-row'>
          <Grid.Column>
            {/* {contextualContent} */}
            {ratingAndFeedbackContainer}
            {receiptCTAContainer}
            {emailReceiptContent}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign='left'>
            <Header as='h4' style={{ color: '#666', fontWeight: '0.9em' }}>Receipt</Header>

            <RecipientInfo {...currentInstance.recipient} hideTimestamp={true} />

            <Table basic='very' unstackable size='small' className='receipt-details'>
              <Table.Body>
                <Table.Row>
                  <Table.Cell className='receipt-field-label'>
                    Transaction Time
                  </Table.Cell>
                  <Table.Cell className='receipt-field-value'>
                    {formattedDate}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell className='receipt-field-label' verticalAlign='top'>
                    Total Amount
                  </Table.Cell>
                  <Table.Cell className='receipt-field-value'>
                    {totalDisplay} {currency.toUpperCase()}<br />
                    {!currentInstance.feesAreCovered &&
                      <span className='amount-details'>{tipDisplay} tip + {feeDisplay} transaction fee</span>
                    }
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell className='receipt-field-label'>
                    Payment Method
                  </Table.Cell>
                  <Table.Cell className='receipt-field-value'>
                    {payment.method.toUpperCase()}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell className='receipt-field-label'>
                    Transaction ID
                  </Table.Cell>
                  <Table.Cell className='receipt-field-value transaction-id'>
                    {paymentIntent.id}
                  </Table.Cell>
                </Table.Row>

              </Table.Body>
            </Table>

          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign='center'>
            <Button id='exit-receipt'
              fluid
              as='a' href={currentInstance.slug}>
              EXIT
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

Receipt.contextType = YouTipContext;
