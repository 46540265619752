/*
----- card number placeholder should adjust when in DEMO env ------
what's the best way to handle env var, w/o needing to build the frontend on-server or in a container?...
*/

import React from 'react';
import {
  Button,
  Grid,
  Icon,
} from 'semantic-ui-react';

import {
  ElementsConsumer
} from '@stripe/react-stripe-js';

import { YouTipContext } from '../../YouTipContextProvider';

/*
export default function CreditCardForm() {
  const [busy, setbusy] = useState(false);
  const [error, setError] = useState(null);
  const [successful, setSuccessful] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [payment, setPayment] = useState(null);

  useEffect(() => {
  });
}
*/

class CreditCardForm extends React.Component {
  constructor(props) {
    super(props);

    this._mounting = false;
    this._stripeInputEvents = {
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null
    };
    this.state = {
      mounted: false,
      isInView: false,
      error: null,
      processing: false,
      events: this._stripeInputEvents
    };

    this.mountStripeElements = this.mountStripeElements.bind(this);
    this.validate = this.validate.bind(this);
    this.handleChangeCardInfo = this.handleChangeCardInfo.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.renderFormView = this.renderFormView.bind(this);
    this.renderClosedView = this.renderClosedView.bind(this);
  }

  // componentDidMount() { this.mountStripeElements(); }
  componentDidUpdate() { this.mountStripeElements(); }
  // componentDidMount() { console.debug(`--------> CC form mounted...`); }
  // componentWillUnmount() { console.debug(`--------> CC form will unmount...`); }

  mountStripeElements() {
    const { stripe, elements } = this.props;
    if (!stripe || !this.state.isInView || this.state.mounted || this._mounting)
      return;

    this._mounting = true;
    const style = {
      base: {
        fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
        fontSize: '20px',
        fontWeight: '200',
        iconColor: '#666EE8',
        color: '#333',
        '::placeholder': {
          color: '#ccc'
        },
      },
      invalid: {
        color: '#e25950'
      }
    };

    let elCardNumber = elements.create('cardNumber', {
      style,
      placeholder: process.env.REACT_APP_STRIPE_ENV === 'test'
        ? 'Use card 4242 4242 4242 4242'
        : '0000 0000 0000 0000'
    });
    let elCardExpiry = elements.create('cardExpiry', { style });
    let elCardCvc = elements.create('cardCvc', { style });

    elCardNumber.mount('#card-number-element');
    elCardExpiry.mount('#card-expiry-element');
    elCardCvc.mount('#card-cvc-element');

    [elCardNumber, elCardExpiry, elCardCvc].forEach(el => {
      el.on('change', this.handleChangeCardInfo);
    });

    this._mounting = false;
    this.setState({ mounted: true });
  }

  componentDidMount() {
    // console.debug(`CC form mounted...`);
  }
  componentWillUnmount() {
    // console.debug(`CC form will unmount...`);
  }

  validate() {
    const { utils, transaction, currentInstance } = this.context;

    if (!currentInstance.checkConfiguredInputs() || !utils.meetsMinimum(transaction.tip)) {
      return false;
    }
    const { error, events } = this.state;
    return !error && Object.values(events).filter(e => e && e.complete && !e.error).length;
  }

  handleChangeCardInfo(stripeEvent) {
    const { elementType, brand, error } = stripeEvent;
    const existingError = this.state.error;

    this._stripeInputEvents[elementType] = stripeEvent;
    let stateUpdate = { events: this._stripeInputEvents };

    if (existingError && existingError.elementType === elementType) {
      if (!error) {
        // console.debug(`-> existing error for ${elementType}; new input is good`);
        // this.setState({ error: null, events: this._stripeInputEvents });
        stateUpdate.error = null;
      }
      // else {
      //   console.debug(`-> cc update bad, existing error for ${elementType} vs new error: `, existingError, error);
      //   this.setState({ error: { elementType, message: error.message }});
      // }
    } else if (error) {
      // console.debug(`-> no existing error for ${elementType}; new input has error: `, error);
      // this.setState({ error: { elementType, message: error.message }, events: this._stripeInputEvents});
      stateUpdate.error = { elementType, message: error.message };
    } else {
      // console.debug(`-> no existing error for ${elementType}; new input is good`);
      if (elementType === 'cardNumber') {
        // console.debug(`-> cc updated card number, brand `, brand);
        // this.setState({ cardType: brand, events: this._stripeInputEvents });
        stateUpdate.cardType = brand;
      }
    }

    // console.debug(`-> error handling checked; updating state: `, stateUpdate);
    this.setState(stateUpdate);
  }

  async handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const { error } = this.state;
    if (error) {
      console.debug(`-> [ERROR] handleSubmit of cc form has an issue: `, error);
      return false;
    }

    // console.debug(`=$ handleSubmit for CC form; processing... `);

    const { stripe, elements } = this.props;
    const { stripePayments } = this.context;

    const cardData = elements.getElement('cardNumber');
    // if (!stripePayments) {
    //   console.warn(`-> [WTF?] stripePayments isn't in context?...`, this.context);
    //   return false;
    // }

    if (!stripePayments.getClientSecret())
      await stripePayments.findOrCreatePaymentIntent();

    // console.debug(`=$ cardData to be sent: `, cardData);

    const paymentResponse = await stripe.confirmCardPayment(
      stripePayments.getClientSecret(), {
        payment_method: { card: cardData }
      }
    );

    const { paymentError, paymentIntent } = paymentResponse;
    if (paymentError || !paymentIntent) {
      console.warn('-> [error] handling payment events ', paymentError, paymentIntent);
      return false;
    } else if (paymentIntent.status === 'succeeded') {
      // console.debug(`=> successfully processed payment. full response: `, paymentResponse);
      //this.setState({ paymentComplete: true, paymentResponse });
      return paymentResponse;
    } else {
      console.warn('-> [] no error (nor .success field) found on payment data... ');
      return false;
    }
  }

  async handleMouseUp(e) {
    e.stopPropagation();
    e.preventDefault();

    const { processing, cardType } = this.state;
    const { lockUi, handlePaymentSelection, unlockUi } = this.context;

    if (processing) return;

    this.setState({ processing: true });

    lockUi();
    await this.handleSubmit(e).then(async (result) => {
      if (result) {
        // console.debug(`-> handleSubmit -> got result, now for handlePaymentSelection; result: `, result);
        await handlePaymentSelection(cardType || 'cc', result);
      } else {
        console.debug(`-> handleSubmit -> error, probably form-related (such as missing field(s))`, this.state);
      }
      unlockUi();
      this.setState({ processing: false });
    });
  }

  renderClosedView() {
    const { isInView } = this.state;
    //const { currentInstance } = this.context;

    return <Button id='pay-with-cc-button-toggle'
                   //disabled={!currentInstance.checkConfiguredInputs()}
                   fluid
                   key='cc-form-toggle'
                   size='large'
                   onMouseUp={() => {
                     this.mountStripeElements();
                     this.setState({ isInView: true });
                   }}
                   style={{ display: isInView ? 'none' : 'block' }}
           >
             Pay with Card
           </Button>;
  }

  render() {
    return (
      <Grid.Row centered id='pay-with-cc-view'>
        <Grid.Column>

          {this.renderClosedView()}
          {this.renderFormView()}

          <div className='credit-card-logos'>
            <img src="credit-card-logos.png" alt="AmEx Discover MasterCard Visa logos" />
          </div>
        </Grid.Column>
      </Grid.Row>
    );
  }

  renderFormView() {
    const { isInView, error, processing } = this.state;
    const hiddenProp = isInView ? {} : { hidden: 'hidden '};

    // const xmissingFields = Object.values(this._stripeInputEvents).findIndex(e => e === null);
    // const hasMissingFields = Object.values(events).findIndex(e => e === null || e.blank);
    // const disabledProp = (error || hasMissingFields > -1) ? { disabled: true } : {};
    const disabledProp = this.validate() ? {} : { disabled: true };

    return (
      <div key={'cc-form-container'} {...hiddenProp}>
        <div key={'cc-form'} id="pay-with-cc-form">
          <div className='ui field cc-form-cancel'>
            <button className='change-payment-method link small' onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.setState({ isInView: false });
            }} style={{ cursor: 'pointer' }}><Icon name='arrow circle left' size='large' /> Cancel</button>
          </div>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <div className='ui field'>
                  <label>Card Number</label>
                  <div id="card-number-element"
                       ref={(el) => this._cardNumberInput = el}></div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid columns='equal' style={{marginTop:0}}>
            <Grid.Row>
              <Grid.Column>
                <div className='ui field'>
                  <label>Expiry Date</label>
                  <div id="card-expiry-element"
                       ref={(el) => this._cardExpiryInput = el}></div>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className='ui field'>
                  <label>Security Code</label>
                  <div id="card-cvc-element"
                       ref={(el) => this._cardCvcInput = el}></div>
                </div>
              </Grid.Column>
            </Grid.Row>
            {error &&
             <Grid.Row id='pay-with-cc-error-message'>
               <Grid.Column>
                 <div id='pay-with-cc-error-message-2'>
                   {error.message}
                 </div>
               </Grid.Column>
             </Grid.Row>
            }
          </Grid>
        </div>

        <Button fluid
                key={'cc-form-submit-button'}
                {...disabledProp}
                id='pay-with-cc-button'
                className='bg-green'
                onClick={(e) => {
                  e.preventDefault();
                  // console.debug(`-> onclick cc submit button`);
                }}
                onMouseDown={(e) => {
                  //console.debug(`-> mousedown on cc submit button`);
                }}
                onMouseUp={this.handleMouseUp}
        >
          {processing
           ? (<><Icon loading name='spinner' />  Processing...</>)
           : `Submit Payment`}
        </Button>

      </div>
    );
  }
};

CreditCardForm.contextType = YouTipContext;

const InjectedCreditCardForm = (props) => {
  return <ElementsConsumer>
           {({ stripe, elements }) => <CreditCardForm stripe={stripe} elements={elements} {...props} />}
         </ElementsConsumer>;
};

export default InjectedCreditCardForm;
