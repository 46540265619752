import React, { Component } from 'react';
import {
  Grid,
  Label,
  Segment
} from 'semantic-ui-react';

import CurrencyInput from './vendor/CurrencyInput.tsx';

import RecipientInfo from './RecipientInfo';

import { YouTipContext } from '../YouTipContextProvider';

export default class TipContainer extends Component {
  renderPresetTips() {
    const { currentInstance, handleTipChange } = this.context;
    return currentInstance.getPresetTips().map((presetCents, idx) =>
      <TipPresetLabel key={idx}
        amount={presetCents / 100}
        onClick={async () => {
          await handleTipChange(presetCents);
          await this.saveTipChange();
        }} />
    );
  }

  async saveTipChange() {
    const { transaction, utils } = this.context;
    if (!utils.meetsMinimum(transaction.tip))
      return;

    const { lockUi, saveChanges, unlockUi } = this.context;
    lockUi();
    let intent = await saveChanges();
    unlockUi();
    return intent;
  }

  displayRecipientList() {
    const { currentInstance } = this.context;
    const { recipientList } = currentInstance.content;
    if (!recipientList) return null;

    const {
      metadataKey = 'selectedRecipient',
      placeholder = 'Select a recipient...',
      items
    } = recipientList;

    //console.log(metadataKey, placeholder, items);

    const entries = items.map((item, idx) => {
      const {
        key,
        value,
        label
      } = item;
      return <option value={value} key={key || value}>{label}</option>
    });

    const currentSelection = currentInstance.metadata[metadataKey] || null;

    return (
      <div key={`${metadataKey}-container`}>
        <div className='recipient-list-container'>
          <select
            name={metadataKey}
            selectedindex={currentSelection}
            onChange={async e => {
              //const { metadataKey = `optionalTipInput${idx}` } = inputData;
              //console.log(`--------- selected item: `, e.target.selectedIndex, e.target.selectedIndex > 0 ? items[e.target.selectedIndex - 1] : null);
              currentInstance.setMetadata({ [metadataKey]: e.target.selectedIndex > 0 ? items[e.target.selectedIndex - 1].value : null });
              //console.log(currentInstance.getMetadata());
              await this.saveTipChange();
            }}
            className="ui dropdown">
            <option>{placeholder}</option>
            {entries}
          </select>
        </div>
      </div>
    );
    /*
    let hasRequired = false;
    const elInputGroup = extraInputs.map((inputData, idx) => {
      const {
        metadataKey = `optionalTipInput${idx}`,
        inputType = 'text',
        required = false,
        saveOnBlur = true,
        saveOnChange = false,
      } = inputData;
      if (required) hasRequired = true;
      const currentValue = currentInstance.metadata[metadataKey] || null;

          <div className='optional-tip-input-field ui input'>
            <input type={inputType}
              name={metadataKey}
              placeholder={inputData.placeholder}
              //value={currentInstance.getMetadata()[metadataKey] || inputData.defaultValue}
              //value={currentValue}
              onChange={async e => {
                //const { metadataKey = `optionalTipInput${idx}` } = inputData;
                currentInstance.setMetadata({ [metadataKey]: e.target.value });
                if (saveOnChange) await this.saveTipChange();
              }}
              onBlur={async e => {
                if (saveOnBlur) await this.saveTipChange();
              }}

              required={required}
              className="input" />
          </div>
    */
  }

  displayOptionalTipInputs() {
    const { currentInstance } = this.context;
    const { optionalTipInput } = currentInstance.content;
    if (!optionalTipInput) return null;

    const extraInputs = [optionalTipInput].flat(); // to support single or multi inputs config
    let hasRequired = false;

    const elInputGroup = extraInputs.map((inputData, idx) => {
      const {
        metadataKey = `optionalTipInput${idx}`,
        inputType = 'text',
        required = false,
        saveOnBlur = true,
        saveOnChange = false,
      } = inputData;

      if (required) hasRequired = true;

      const currentValue = currentInstance.metadata[metadataKey] || null;

      return (
        <div key={`${metadataKey}-${idx}`}>
          {inputData.label &&
            <div className="optional-tip-input-label">
              {inputData.label}
              {required && !currentValue &&
                <span className="optional-tip-input-label required">*</span>
              }
            </div>
          }

          <div className='optional-tip-input-field ui input'>
            <input type={inputType}
              name={metadataKey}
              placeholder={inputData.placeholder}
              //value={currentInstance.getMetadata()[metadataKey] || inputData.defaultValue}
              //value={currentValue}
              onChange={async e => {
                //const { metadataKey = `optionalTipInput${idx}` } = inputData;
                currentInstance.setMetadata({ [metadataKey]: e.target.value });
                if (saveOnChange) await this.saveTipChange();
              }}
              onBlur={async e => {
                if (saveOnBlur) await this.saveTipChange();
              }}

              required={required}
              className="input" />
          </div>


        </div>
      );
    });

    return (
      <div className="optional-tip-input-group">
        {elInputGroup}
        {hasRequired &&
          <div className="optional-tip-input-note">
            <span className="optional-tip-input-label required">*</span> fields that require a value.
          </div>
        }
      </div>
    )


    /*
    return (
      <div className='optional-tip-input-field ui input'>
        <input type="text"
          name="optional-tip-input"
          placeholder={optionalTipInput.placeholder}
          value={currentInstance.metadata.optionalTipInput}
          onChange={e => {
            const { metadataKey = 'optionalTipInput' } = optionalTipInput;
            currentInstance.setMetadata({ [metadataKey]: e.target.value });
          }}
          onBlur={async e => {
            await this.saveTipChange();
          }}
          className="input" />
      </div>);
    */
  }

  render() {
    const { currentInstance,
      // tipEntered,
      transaction,
      handleTipChange,
      // saveChanges,
      // whenReady,
      // isDirty,
      // lockUi,
      // unlockUi,
      utils } = this.context;

    // const dirty = isDirty();
    const strParams = {
      currency: currentInstance.currency,
      locale: currentInstance.locale
    };
    //We appreciate the tip!
    const transactionInfo = currentInstance.feesAreCovered
      ? <React.Fragment>
        &nbsp;
      </React.Fragment>
      : currentInstance.content.transactionInfoAsTable
        ? <React.Fragment>
          <table className='transaction-info-table' align="center">
            <tbody><tr className='transaction-info-fee'>
              <td align="left">Transaction Fee</td>
              <td align="right">
                <span>{utils.centsToString(transaction.fee, strParams)}</span>
              </td>
            </tr>
              <tr className='transaction-info-total'>
                <td align="left">Total</td>
                <td align="right">
                  <span>{utils.centsToString(transaction.total, strParams)}</span>
                </td>
              </tr></tbody>
          </table>
        </React.Fragment>
        : <React.Fragment>
          Transaction Fee: <span>{utils.centsToString(transaction.fee, strParams)}</span>
          &nbsp; Total: <span>{utils.centsToString(transaction.total, strParams)}</span>
        </React.Fragment>;

    return (
      <Grid.Row centered>
        <Grid.Column>
          <Segment.Group raised id='tip-container-segment'>
            <Segment attached='top'>
              {/* <Progress percent={50} attached='top' /> */}

              <RecipientInfo
                {...currentInstance.recipient}
              />
              {this.displayRecipientList()}

            </Segment>
            <Segment attached textAlign='center'>
              <div className='tip-amount-header'>
                {currentInstance.content.tipAmountHeader}
              </div>
              <div>
                <div className='ui left icon input tip-amount-input'>
                  <CurrencyInput
                    className='tip-amount-input-currency'
                    max={100000000}
                    onValueChange={(val) => {
                      // console.debug(`onValueChange -> ${val}`);
                      handleTipChange(val);
                    }}
                    onBlur={async (e) => {
                      // console.debug(`onBlur -> ${e.target}`, e);
                      if (utils.meetsMinimum(transaction.tip))
                        await this.saveTipChange();
                    }}
                    onFocus={async (e) => {
                      // console.debug(`onFocus -> ${e.target}`, e);
                    }}
                    onFocusOut={async (e) => {
                      // console.debug(`onFocusOut -> ${e.target}`, e);
                    }}
                    style={{ textAlign: 'right' }}
                    value={transaction.tip}
                    currency={currentInstance.currency}
                    locale={currentInstance.locale}
                  />

                </div>
                <div className='tip-fee-info'>
                  {!utils.meetsMinimum(transaction.tip)
                    ? `Minimum: ${utils.centsToString(utils.minimum(), strParams)}`
                    : transactionInfo}
                </div>

                {this.displayOptionalTipInputs()}

              </div>

            </Segment>
            <Segment id='tip-presets' attached='bottom' textAlign='center'>
              {!currentInstance.content.hidePresetTipsLabel && <span>Quick Tip! </span>}
              {this.renderPresetTips()}
            </Segment>
          </Segment.Group>

        </Grid.Column>
      </Grid.Row>
    );
  }
};

const TipPresetLabel = ({ amount, onClick }) => {
  const classes = amount > 99 ? 'youtip preset-left-adjust' : 'youtip';
  // const classes = 'youtip';
  return (
    <Label circular
      size='huge'
      className={classes}
      onClick={onClick}>
      ${amount}
    </Label>
  );
};

TipContainer.contextType = YouTipContext;
