import React from 'react';
import {
  ElementsConsumer,
} from '@stripe/react-stripe-js';

import { YouTipContext } from '../YouTipContextProvider';

import {
  Button,
  Grid,
} from 'semantic-ui-react';

// const ELEMENT_OPTIONS = {
//   style: {
//     paymentRequestButton: {
//       theme: 'dark',
//     },
//   },
// };

let __paymentRequest;
let __loading = false;

class StripePaymentRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasCheckedPaymentSupport: false,
      supportsPaymentRequest: null
    };

    this.initPaymentRequest = this.initPaymentRequest.bind(this);
    this.findOrCreatePaymentRequest = this.findOrCreatePaymentRequest.bind(this);
    this.updatePaymentRequest = this.updatePaymentRequest.bind(this);
    this.cancelPaymentRequest = this.cancelPaymentRequest.bind(this);
    this.handleBrowserPay = this.handleBrowserPay.bind(this);
    this.renderApplePay = this.renderApplePay.bind(this);
    this.renderGPay = this.renderGPay.bind(this);
  }

  componentDidUpdate() {
    const { stripePayments } = this.context;
    const { hasCheckedPaymentSupport } = this.state;
    if (stripePayments.getPaymentIntent() && !hasCheckedPaymentSupport) {
      this.initPaymentRequest();
    }
  }

  async initPaymentRequest() {
    if (__loading) {
      return null;
    }

    if (__paymentRequest) {
      return __paymentRequest;
    }

    const { stripePayments, handlePaymentSelection } = this.context;
    const { stripe } = this.props;
    __loading = true;
    const paymentRequest = await this.findOrCreatePaymentRequest();

    //return request.then(paymentRequest => {
    if (!paymentRequest) {
      __loading = false;
      return null;
    }

    paymentRequest.on('paymentmethod', async (e) => {
      const clientSecret = stripePayments.getClientSecret();
      const result = await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: e.paymentMethod.id },
        { handleActions: false }
      );

      const { paymentIntent, error } = result;

      const paymentData = Object.assign({}, { paymentMethod: e.paymentMethod }, result);
      console.debug(`-> digital wallet; full payment data to send to handlePaymentSelection`, paymentData);

      if (error) {
        e.complete('fail');
      } else {
        if (paymentIntent.status === 'requires_action') {
          const { error } = await stripe.confirmCardPayment(clientSecret);
          if (error) {
            console.warn(`=$ error: `, error);
            e.complete('fail');
          } else {
            e.complete('success');
            handlePaymentSelection('browser', paymentData);
          }
        } else {
          e.complete('success');
          handlePaymentSelection('browser', paymentData);
        }
      }
    });
    paymentRequest.on('cancel', (e) => {
      console.debug(`=digital-wallet=$ payment request event: cancel`, e);
    });

    __paymentRequest = paymentRequest;
    __loading = false;

    return __paymentRequest;
  }

  async findOrCreatePaymentRequest() {
    if (__paymentRequest)
      return __paymentRequest;

    const { currentInstance, readyToPerformTransaction, transaction, lockUi, unlockUi } = this.context;
    if (!currentInstance || !readyToPerformTransaction())
      return null;// console.debug(`=$ not ready to create payment request!`);

    const { stripe } = this.props;

    lockUi();
    __paymentRequest = await stripe.paymentRequest({
      country: 'US',
      currency: currentInstance.currency,
      total: {
        label: `youtip ${currentInstance.displayNameForCharge}`,
        amount: currentInstance.feesAreCovered ? transaction.tip : transaction.total
      },
      // metadata: currentInstance.getMetadata()
    });

    if (!__paymentRequest) {
      console.debug(`=$ [error] no payment request from stripe!`);
      return null;
    }

    const canBrowserPay = await __paymentRequest.canMakePayment();
    this.setState({
      supportsPaymentRequest: canBrowserPay ? true : false,
      hasCheckedPaymentSupport: true
    });

    unlockUi();

    return __paymentRequest;
  }

  async updatePaymentRequest() {
    const { transaction, currentInstance } = this.context;

    if (!__paymentRequest) {
      console.warn(`=$ cannot update, payment request is not loaded`);
      return null;
    }

    const result = await __paymentRequest.update({
      total: {
        label: `youtip ${currentInstance.displayNameForCharge}`,
        amount: currentInstance.feesAreCovered ? transaction.tip : transaction.total
      }
    });

    if (result) {
      const canBrowserPay = await result.canMakePayment();
      this.setState({
        supportsPaymentRequest: canBrowserPay ? true : false,
        hasCheckedPaymentSupport: true
      });
    }
    // return result;
    return __paymentRequest;
  }

  cancelPaymentRequest() {
    __paymentRequest = null;
  }

  async handleBrowserPay(e) {
    e.preventDefault();
    e.stopPropagation();

    const { utils, transaction } = this.context;
    if (!utils.meetsMinimum(transaction.tip))
      return;

    if (!__paymentRequest) {
      console.debug(`-> handleBrowserPay, payment request not loaded, init payment request`);
      await this.initPaymentRequest();
      console.debug(`-> handleBrowserPay, payment request initialized`, __paymentRequest);
    } else {
      await this.updatePaymentRequest();
      console.debug(`-> handleBrowserPay, updated payment request`, __paymentRequest);
    }

    if (__paymentRequest) {
      const supported = await __paymentRequest.canMakePayment();
      if (supported) {
        this.setState({ supportsPaymentRequest: true, hasCheckedPaymentSupport: true });
        __paymentRequest.show();
      }
    }
  }

  renderGPay() {
    const { supportsPaymentRequest, hasCheckedPaymentSupport } = this.state;
    const classNames = 'applepay-button small ' + (
      (supportsPaymentRequest === false && hasCheckedPaymentSupport) ? 'disabled' : ''
    );

    return (<Button fluid color='black' className={classNames}
      id='google-pay-button'
      key='google-pay-button'
      style={{ marginTop: '0.5em' }}
      onClick={(e) => {
        e.preventDefault();
      }}
      onMouseUp={this.handleBrowserPay}
    >
      <img alt='GPay' src='google-pay-button-logo.png' style={{ maxHeight: '20px' }} />
    </Button>);
  }

  renderApplePay() {
    const { supportsPaymentRequest, hasCheckedPaymentSupport } = this.state;
    const classNames = 'applepay-button small ' + (
      (supportsPaymentRequest === false && hasCheckedPaymentSupport) ? 'disabled' : ''
    );

    return <Button fluid color='black'
      id='apple-pay-button'
      key='apple-pay-button'
      className={classNames}
      style={{ marginTop: '0.5em' }}
      onClick={(e) => {
        e.preventDefault();
      }}
      onMouseUp={this.handleBrowserPay}
    >
      <img alt='ApplePay' src='apple-pay-button-logo-white.png' style={{ maxHeight: '19px' }} />
    </Button>;
  }

  render() {
    return (
      <Grid columns='equal'>
        <Grid.Row style={{ paddingTop: '0', paddingBottom: '0', marginTop: '1rem', marginBottom: '1rem' }}>
          <Grid.Column className='applepay-col'>
            {this.renderApplePay()}
          </Grid.Column>
          <Grid.Column className='googlepay-col'>
            {this.renderGPay()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};


StripePaymentRequest.contextType = YouTipContext;

const InjectedStripePaymentRequest = (props) => {
  return <ElementsConsumer>
    {({ stripe }) => <StripePaymentRequest stripe={stripe} {...props} />}
  </ElementsConsumer>;
};

export default InjectedStripePaymentRequest;
