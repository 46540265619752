import './TermsOfUse.css';

const TermsOfUse = () => {
  return (
    <div id="terms-of-use">
      <div className="c5 c15">
        <p className="c13"><span className="c2">Terms of Use</span></p>
        <p className="c1"><span className="c3">Last Modified: January 29, 2021</span><a id="id.30j0zll"> </a></p>
        <p className="c1"><span className="c3">These terms of use are entered into by and between You and youtip, Inc. (&ldquo;</span><span className="c2">Company</span><span className="c3">,&rdquo; &ldquo;</span><span className="c2">we</span><span className="c3">,&rdquo; or &ldquo;</span><span className="c2">us&rdquo;</span><span className="c3">). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, &ldquo;</span><span className="c2">Terms of Use&rdquo;</span><span className="c3">), govern your access to and use of </span><span className="c3"><a className="c6" href="https://www.google.com/url?q=http://www.youtip.money&amp;sa=D&amp;source=editors&amp;ust=1612726082215000&amp;usg=AOvVaw3mzjmvsSAkrqXBd7mKIN9l">www.youtip.money</a></span><span className="c3">, www.youtip.pro, and any user interface, content, functionality, and services offered on or through these domains or by us (collectively, the &ldquo;</span><span className="c2">Website&rdquo;</span><span className="c3">).</span></p>
        <p className="c1"><span className="c2">1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Acceptance of the Terms of Use</span><a id="id.1fob9te"> </a></p>
        <p className="c1"><span className="c3">Please read the Terms of Use carefully before you start to use the Website. </span><span className="c2">By using the Website or accepting or agreeing to the Terms of Use when you click any of the pay buttons in the user interface, you accept and agree to be bound and abide by these Terms of Use and our </span><span className="c2"></span><a href="#privacy-policy" id="cmnt_ref1">Privacy Policy</a><span className="c2">, incorporated herein by reference.</span><span className="c3">&nbsp;If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Website. </span><a id="id.3znysh7"> </a></p>
        <p className="c1"><span className="c3">This Website is offered and available to users who are 18 years of age or older and reside in the United States or any of its territories or possessions. By using this Website, you represent and warrant that you are of legal age to form a binding contract with the Company and meet all of the stated eligibility requirements. If you do not meet all of these requirements, you must not access or use the Website.</span><a id="id.2et92p0"> </a></p>
        <p className="c1"><span className="c2">2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Changes to the Terms of Use</span><a id="id.tyjcwt"> </a></p>
        <p className="c1"><span className="c3">We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them and apply to all access to and use of the Website thereafter. However, any changes to the dispute resolution provisions set out in </span><span className="c3"><a className="c6" href="#id.3cqmetx">Governing Law and Jurisdiction</a></span><span className="c3">&nbsp;will not apply to any disputes for which the parties have actual notice on or before the date the change is posted on the Website. </span><a id="id.3dy6vkm"> </a></p>
        <p className="c1"><span className="c3">Your continued use of the Website following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page each time you access this Website, so you are aware of any changes, as they are binding on you. </span><a id="id.1t3h5sf"> </a></p>
        <p className="c1"><span className="c2">3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;What we Do</span></p>
        <p className="c1"><span className="c3">We provide a user interface to allow our business clients to use unique QR codes to provide customers a way to leave tips for their service professionals and provide feedback to those businesses regarding those services. This user interface allows a customer to leave a tip by making a digital payment instead of leaving cash. </span></p>
        <p className="c1"><span className="c2">4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Processing through Stripe</span></p>
        <p className="c1"><span className="c3">The payment processing services on our Website are provided by a third party. For businesses to allow us to enable payment processing services, you must provide us accurate and complete information about you and your business and authorize us to share it and transaction information related to your use of the payment processing services provided by to us.</span></p>
        <p className="c1"><span className="c2">5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fees and Disputes</span></p>
        <p className="c1"><span className="c3">When you submit a tip through our user-interface, the convenience fee that we charge on the tip will be disclosed. If have a dispute about a tip entered with us, you may contact us at info@youtip.money.</span></p>
        <p className="c1"><span className="c2">6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Accessing the Website and Account Security</span><a id="id.4d34og8"> </a></p>
        <p className="c1"><span className="c3">We reserve the right to withdraw or amend this Website, and any service or material we provide on the Website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website.</span><a id="id.2s8eyo1"> </a></p>
        <p className="c1"><span className="c3">It is a condition of your use of the Website that all the information you provide on the Website is correct, current, and complete. You agree that all information you provide to the Website or otherwise, including, but not limited to, through the use of any interactive features on the Website, is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</span><a id="id.17dp8vu"> </a></p>
        <p className="c1"><span className="c2">7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Intellectual Property Rights</span><a id="id.3rdcrjn"> </a></p>
        <p className="c1"><span className="c3">The Website and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by the Company, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</span><a id="id.26in1rg"> </a></p>
        <p className="c1"><span className="c3">These Terms of Use permit you to use the Website for your personal, non-commercial use only or for legitimate business purposes as agreed by the Company. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, except as follows:</span></p>
        <ul className="c8 lst-kix_list_31-0 start">
          <li className="c0 li-bullet-0"><span className="c3">Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">You may store files that are automatically cached by your Web browser for display enhancement purposes.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">You may print or download one copy of a reasonable number of pages of the Website for your own personal, non-commercial use and not for further reproduction, publication, or distribution.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">If we provide social media features with certain content, you may take such actions as are enabled by such features.</span><a id="id.lnxbz9"> </a></li>
        </ul>
        <p className="c1"><span className="c3">You must not:</span></p>
        <ul className="c8 lst-kix_list_31-0">
          <li className="c0 li-bullet-0"><span className="c3">Modify copies of any materials from this site.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.</span><a id="id.35nkun2"> </a></li>
        </ul>
        <p className="c1"><span className="c3">If you wish to make any use of material on the Website other than that set out in this section, please address your request to: info@youtip.money.</span><a id="id.1ksv4uv"> </a></p>
        <p className="c1"><span className="c3">If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Website in breach of the Terms of Use, your right to use the Website will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Website or any content on the Website is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.</span><a id="id.44sinio"> </a></p>
        <p className="c1"><span className="c2">8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Trademarks</span><a id="id.2jxsxqh"> </a></p>
        <p className="c1"><span className="c3">The Company name, its logo, and all related names, logos, product and service names, designs, and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs, and slogans on this Website are the trademarks of their respective owners.</span><a id="id.z337ya"> </a></p>
        <p className="c1"><span className="c2">9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Prohibited Uses</span><a id="id.3j2qqm3"> </a></p>
        <p className="c1"><span className="c3">You may use the Website only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Website:</span></p>
        <ul className="c8 lst-kix_list_31-0">
          <li className="c0 li-bullet-0"><span className="c3">In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries). </span></li>
          <li className="c0 li-bullet-0"><span className="c3">For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any &ldquo;junk mail,&quot; &quot;chain letter,&quot; &quot;spam,&quot; or any other similar solicitation.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing).</span></li>
          <li className="c0 li-bullet-0"><span className="c3">To engage in any other conduct that restricts or inhibits anyone&#39;s use or enjoyment of the Website, or which, as determined by us, may harm the Company or users of the Website, or expose them to liability.</span><a id="id.1y810tw"> </a></li>
        </ul>
        <p className="c1"><span className="c3">Additionally, you agree not to:</span></p>
        <ul className="c8 lst-kix_list_31-0">
          <li className="c0 li-bullet-0"><span className="c3">Use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party&#39;s use of the Website, including their ability to engage in real time activities through the Website.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Use any robot, spider, or other automatic device, process, or means to access the Website for any purpose, including monitoring or copying any of the material on the Website.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Use any manual process to monitor or copy any of the material on the Website, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Use any device, software, or routine that interferes with the proper working of the Website.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer, or database connected to the Website. </span></li>
          <li className="c0 li-bullet-0"><span className="c3">Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Otherwise attempt to interfere with the proper working of the Website.</span><a id="id.4i7ojhp"> </a></li>
        </ul>
        <p className="c1"><span className="c2">10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Services Feedback</span><a id="id.2xcytpi"> </a></p>
        <p className="c1"><span className="c3">The Website allows consumers to leave star ratings and comments (&ldquo;</span><span className="c2">Feedback</span><span className="c3">&rdquo;) that we will provide to third-party businesses. &nbsp;</span><a id="id.1ci93xb"> </a></p>
        <p className="c1"><span className="c3">By providing any Feedback, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns the right to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such Feedback. </span><a id="id.3whwml4"> </a></p>
        <p className="c1"><span className="c3">You represent and warrant that all of your Feedback will comply with these Terms of Use. </span><a id="id.2bn6wsx"> </a></p>
        <p className="c1"><span className="c3">You understand and acknowledge that you are responsible for any Feedback you submit or contribute, and you, not the Company, have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness.</span><a id="id.qsh70q"> </a></p>
        <p className="c1"><span className="c3">We are not responsible or liable to any third party for the content or accuracy of any Feedback provided by you or any other user of the Website. </span><a id="id.3as4poj"> </a></p>
        <p className="c1"><span className="c2">11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Monitoring and Enforcement; Termination</span><a id="id.1pxezwc"> </a></p>
        <p className="c1"><span className="c3">We have the right to:</span></p>
        <ul className="c8 lst-kix_list_31-0">
          <li className="c0 li-bullet-0"><span className="c3">Take any action with respect to any Feedback that we deem necessary or appropriate in our sole discretion, including if we believe that such Feedback violates the Terms of Use, including threatening the personal safety of users of the Website or the public or could create liability for the Company.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Disclose your identity or other information about you to any third party who claims that Feedback shared by you violates their rights, including their intellectual property rights or their right to privacy.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Website. </span></li>
          <li className="c0 li-bullet-0"><span className="c3">Terminate or suspend your access to all or part of the Website for any or no reason, including without limitation, any violation of these Terms of Use.</span><a id="id.49x2ik5"> </a></li>
        </ul>
        <p className="c1"><span className="c3">Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone using our Website. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</span><a id="id.2p2csry"> </a></p>
        <p className="c1"><span className="c3">However, we do not undertake to review all Feedback shared through the Website. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section. </span><a id="id.147n2zr"> </a></p>
        <p className="c1"><span className="c2">12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Content Standards</span><a id="id.3o7alnk"> </a></p>
        <p className="c1"><span className="c3">These content standards apply to any and all Feedback. Feedback must comply with all applicable federal, state, local, and international laws and regulations. Without limiting the foregoing, Feedback must not:</span></p>
        <ul className="c8 lst-kix_list_31-0">
          <li className="c0 li-bullet-0"><span className="c3">Contain any material that is defamatory, obscene, indecent, abusive, harassing, violent, or hateful.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our Privacy Policy.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Be likely to deceive any person.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Promote any illegal activity, or advocate, promote, or assist any unlawful act.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Impersonate any person or misrepresent your identity or affiliation with any person or organization. </span><a id="id.23ckvvd"> </a></li>
        </ul>
        <p className="c1"><span className="c2">13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reliance on Information Posted</span><a id="id.ihv636"> </a></p>
        <p className="c1"><span className="c3">The information presented on or through the Website is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Website, or by anyone who may be informed of any of its contents.</span><a id="id.32hioqz"> </a></p>
        <p className="c1"><span className="c3">This Website may include information provided by third parties. All statements and/or opinions expressed in these materials other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of the Company. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.</span><a id="id.1hmsyys"> </a></p>
        <p className="c1"><span className="c2">14.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Changes to the Website</span><a id="id.41mghml"> </a></p>
        <p className="c1"><span className="c3">We may update the content on this Website from time to time, but its content is not necessarily complete or up to date. Any of the material on the Website may be out of date at any given time, and we are under no obligation to update such material. </span><a id="id.2grqrue"> </a></p>
        <p className="c1"><span className="c2">15.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Information About You and Your Visits to the Website</span><a id="id.vx1227"> </a></p>
        <p className="c1"><span className="c3">All information we collect on this Website is subject to our Privacy Policy. By using the Website, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy. </span><a id="id.3fwokq0"> </a></p>
        <p className="c1"><span className="c2">16.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Linking to the Website and Social Media Features</span><a id="id.1v1yuxt"> </a></p>
        <p className="c1"><span className="c3">You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part without our express written consent. </span><a id="id.4f1mdlm"> </a></p>
        <p className="c1"><span className="c3">This Website may provide certain social media features that enable you to:</span></p>
        <ul className="c8 lst-kix_list_31-0">
          <li className="c0 li-bullet-0"><span className="c3">Link from your own or certain third-party websites to certain content on this Website.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Send emails or other communications with certain content, or links to certain content, on this Website.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Cause limited portions of content on this Website to be displayed or appear to be displayed on your own or certain third-party websites.</span><a id="id.2u6wntf"> </a></li>
        </ul>
        <p className="c1"><span className="c3">You may use these features solely as they are provided by us, and solely with respect to the content they are displayed with, and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, unless you have our express written consent, you must not:</span></p>
        <ul className="c8 lst-kix_list_31-0">
          <li className="c0 li-bullet-0"><span className="c3">Establish a link from any website that is not owned by you.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Cause the Website or portions of it to be displayed on, or appear to be displayed by, any other site, for example, framing, deep linking, or in-line linking.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Link to any part of the Website other than the homepage.</span></li>
          <li className="c0 li-bullet-0"><span className="c3">Otherwise take any action with respect to the materials on this Website that is inconsistent with any other provision of these Terms of Use.</span><a id="id.19c6y18"></a></li>
        </ul>
        <p className="c1"><span className="c3">The website from which you are linking, or on which you make certain content accessible, must comply in all respects with these Terms of Use.</span><a id="id.3tbugp1"></a></p>
        <p className="c1"><span className="c3">You agree to cooperate with us in causing any unauthorized framing or linking immediately to stop. We reserve the right to withdraw linking permission without notice.</span><a id="id.28h4qwu"></a></p>
        <p className="c1"><span className="c3">We may disable all or any social media features and any links at any time without notice in our discretion. </span><a id="id.nmf14n"></a></p>
        <p className="c1"><span className="c2">17.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Links from the Website</span><a id="id.37m2jsg"></a></p>
        <p className="c1"><span className="c3">If the Website contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to this Website, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</span><a id="id.1mrcu09"></a></p>
        <p className="c1"><span className="c2">18.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Geographic Restrictions</span><a id="id.46r0co2"></a></p>
        <p className="c1"><span className="c3">The owner of the Website is based in the United States. We provide this Website for use only by persons located in the United States. We make no claims that the Website or any of its content is accessible or appropriate outside of the United States. Access to the Website may not be legal by certain persons or in certain countries. If you access the Website from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.</span><a id="id.2lwamvv"></a></p>
        <p className="c1"><span className="c2">19.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Disclaimer of Warranties</span><a id="id.111kx3o"></a></p>
        <p className="c1"><span className="c3">You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. </span></p>
        <p className="c1"><span className="c3">TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</span><a id="id.3l18frh"></a></p>
        <p className="c1"><span className="c3">YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. </span><a id="id.206ipza"></a></p>
        <p className="c1"><span className="c3">TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</span><a id="id.4k668n3"></a></p>
        <p className="c1"><span className="c3">THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</span><a id="id.2zbgiuw"></a></p>
        <p className="c1"><span className="c2">20.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Limitation on Liability</span><a id="id.1egqt2p"></a></p>
        <p className="c1"><span className="c3">TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. </span></p>
        <p className="c1"><span className="c3">The limitation of liability set out above does not apply to liability resulting from our gross negligence or willful misconduct.</span><a id="id.3ygebqi"></a></p>
        <p className="c1"><span className="c3">THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</span><a id="id.2dlolyb"></a></p>
        <p className="c1"><span className="c2">21.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Indemnification</span><a id="id.sqyw64"></a></p>
        <p className="c1"><span className="c3">You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys&#39; fees) arising out of or relating to your violation of these Terms of Use or your use of the Website, including, but not limited to, your Feedback, any use of the Website&#39;s content, services, and products other than as expressly authorized in these Terms of Use, or your use of any information obtained from the Website.</span><a id="id.3cqmetx"></a></p>
        <p className="c1"><span className="c2">22.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Governing Law and Jurisdiction</span><a id="id.1rvwp1q"></a></p>
        <p className="c1"><span className="c3">All matters relating to the Website and these Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Delaware without giving effect to any choice or conflict of law provision or rule.</span><a id="id.4bvk7pj"></a></p>
        <p className="c1"><span className="c3">Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use or the Website shall be instituted exclusively in the federal courts of the United States or the courts of the state in which the Company is headquartered, although we retain the right to bring any suit, action, or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</span><a id="id.2r0uhxc"></a></p>
        <p className="c1"><span className="c2">23.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Arbitration</span><a id="id.1664s55"></a></p>
        <p className="c1 c5"><span className="c9">YOU AND THE COMPANY ARE AGREEING TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A COURT OR BEFORE A JURY, OR TO PARTICIPATE IN A CLASS ACTION OR REPRESENTATIVE ACTION WITH RESPECT TO A CLAIM. OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION.</span></p>
        <p className="c1 c5"><span className="c9">ANY CLAIM, DISPUTE OR CONTROVERSY (WHETHER IN CONTRACT, TORT OR OTHERWISE, WHETHER PRE-EXISTING, PRESENT OR FUTURE, AND INCLUDING STATUTORY, CONSUMER PROTECTION, COMMON LAW, INTENTIONAL TORT, INJUNCTIVE AND EQUITABLE CLAIMS) BETWEEN YOU AND US ARISING FROM OR RELATING IN ANY WAY TO YOUR PURCHASE OF PRODUCTS OR SERVICES THROUGH THE SITE, WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION.</span></p>
        <p className="c1 c5"><span className="c4">The arbitration will be administered by the American Arbitration Association (&quot;AAA&quot;) in accordance with the Consumer Arbitration Rules (the &quot;AAA Rules&quot;) then in effect, except as modified by this Section. (The AAA Rules are available at www.adr.org/arb_med or by calling the AAA at 1-800-778-7879.) The Federal Arbitration Act will govern the interpretation and enforcement of this section.</span></p>
        <p className="c1 c5"><span className="c4">The arbitrator will have exclusive authority to resolve any dispute relating to arbitrability and/or enforceability of this arbitration provision, including any unconscionability challenge or any other challenge that the arbitration provision or the agreement is void, voidable, or otherwise invalid. The arbitrator will be empowered to grant whatever relief would be available in court under law or in equity. Any award of the arbitrator(s) will be final and binding on each of the parties and may be entered as a judgment in any court of competent jurisdiction.</span></p>
        <p className="c1 c5"><span className="c4">You may elect to pursue your claim in small-claims court rather than arbitration if you provide us with written notice of your intention do so within 60 days of a transaction through our Website. The arbitration or small-claims court proceeding will be limited solely to your individual dispute or controversy.</span></p>
        <p className="c1 c5"><span className="c4">You agree to an arbitration on an individual basis. In any dispute, NEITHER YOU NOR THE COMPANY WILL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER CUSTOMERS IN COURT OR IN ARBITRATION OR OTHERWISE PARTICIPATE IN ANY CLAIM AS A CLASS REPRESENTATIVE, CLASS MEMBER OR IN A PRIVATE ATTORNEY GENERAL CAPACITY. The arbitral tribunal may not consolidate more than one person&rsquo;s claims and may not otherwise preside over any form of a representative or class proceeding. The arbitral tribunal has no power to consider the enforceability of this class arbitration waiver and any challenge to the class arbitration waiver may only be raised in a court of competent jurisdiction.</span></p>
        <p className="c1 c5"><span className="c4">If any provision of this arbitration agreement is found unenforceable, the unenforceable provision will be severed, and the remaining arbitration terms will be enforced.</span></p>
        <p className="c1"><span className="c2">24.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Limitation on Time to File Claims</span><a id="id.3q5sasy"></a></p>
        <p className="c1"><span className="c3">ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</span><a id="id.25b2l0r"></a></p>
        <p className="c1"><span className="c2">25.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Waiver and Severability</span><a id="id.kgcv8k"></a></p>
        <p className="c1"><span className="c3">No waiver by the Company of any term or condition set out in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</span><a id="id.34g0dwd"></a></p>
        <p className="c1"><span className="c3">If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect. </span><a id="id.1jlao46"></a></p>
        <p className="c1"><span className="c2">26.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Entire Agreement</span><a id="id.43ky6rz"></a></p>
        <p className="c1"><span className="c3">The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and the Company regarding the Website and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Website. </span><a id="id.2iq8gzs"></a></p>
        <p className="c1"><span className="c2">27.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your Comments and Concerns</span><a id="id.xvir7l"></a></p>
        <p className="c1"><span className="c3">This website is operated by youtip, 1440 W. Taylor Street #488 , Chicago, IL 60607.</span><a id="id.3hv69ve"></a></p>
        <p className="c1"><span className="c3">All other feedback, comments, requests for technical support, and other communications relating to the Website should be directed to: </span><span className="c3"><a className="c6" href="mailto:info@youtip.money.com">info@youtip.money.com</a></span><span className="c3">. </span></p>
      </div>
    </div>
  );
};
export default TermsOfUse;
