import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import './index.css';
// import 'semantic-ui-less/semantic.less';
import 'semantic-ui-css/semantic.min.css';
import App from './App';

import reportWebVitals from './reportWebVitals';

import axios from 'axios';

class Tester extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: '',
      name: '',
      amount: '',
      uuid: '',
      paymentId: ''
    };
    this.changeHandler = props.changeHandler;
    this.genString = this.genString.bind(this);
    this.clear = this.clear.bind(this);
  }

  clear() {
    this.setState({
      time: '',
      name: '',
      amount: '',
      uuid: '',
      paymentId: ''
    });
  }

  genString() {
    const { time, name, amount, uuid, paymentId } = this.state;
    let amounts = [];
    if (amount === '10') {
      amounts.push(`$10.00`);
      amounts.push(`$9.01`);
    } else if (amount === '5') {
      amounts.push(`$5.00`);
      amounts.push(`$4.35`);
    } else if (amount === '20') {
      amounts.push(`$20.00`);
      amounts.push(`$18.32`);
    } else if (amount === '25') {
      amounts.push(`$25.00`);
      amounts.push(`$22.97`);
    } else {
      amounts.push(`$${amount}.00`);
      amounts.push(`$`);
    }

    return [time.replace(',',''),
            name,
            amounts.join(','),
            'US',
            '',
            '',
            uuid,
            paymentId].join(',');
  }

  render() {
    const { time, name, amount, uuid, paymentId } = this.state;
    const str = this.genString();
    return (
      <div style={{margin:'0 auto',width:'400px'}}>
        timestamp: <input type="text" value={time}
                          onChange={(e)=>{console.log(e.target);this.setState({time: e.target.value});}}
               id="time" width="30" /><br/>
        name: <input type="text" value={name}
                     onChange={(e)=>{this.setState({name: e.target.value});}}
               id="name" width="30" /><br/>
        uuid: <input type="text" value={uuid}
                     onChange={(e)=>{this.setState({uuid: e.target.value});}}
               id="uuid" width="30" /><br/>
        amount: <input type="text" value={amount}
                       onChange={(e)=>{this.setState({amount: e.target.value});}}
               id="amount" width="30" /><br/>
        payment id: <input type="text" value={paymentId}
                           onChange={(e)=>{this.setState({paymentId: e.target.value});}}
               id="paymentId" width="30" /><br/>

        <div style={{border:'1px solid red'}}>
          {this.genString()}
        </div>
        <button onClick={() => {this.clear();}}>clear</button>
        <button onClick={() => {navigator.clipboard.writeText(this.genString());}}>copy to clipboard</button>
      </div>
    );
  }
};

//
//--------------------------------------------------------------------------
//

const SETTLEMENT_ACCOUNT_ID = "wjkmkJcEgEIzTDE5wgAsCA";

const TABAPAY_ACCOUNT_IDS = [
  "C0skf3xFIc5NC-yp_o8vEA", // COFFEE BANK ACCOUNT
       //"iwgVTzqF0Yhi7N6skG8y0w", // Dade Murphy
  //"jwsFXXUUqca2zcye_13G-g", // Emp 1, John Doe
  //"i0sXfHcFqeanzyV7PTewPg", // Emp 2, Jane Smith
  //"w8MHXHIUKY6LUwemIOMgWQ", // Emp 3, Pat Jones
];

const PNX_API_BASE = 'https://api.youtip.pro/pnx';

const headers = {
  "Authorization": "Bearer jza89q2uZYpDvSaNQ1gGJHhX2yXcfwOzW8qAcig6Uz9LpyUT2HjKGwsV5gyJ10bfdwmW",
  "Content-Type": "application/json"
};

class TabapayTester extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      accounts: {},
      error: null,
      tempTransaction: {
        amount: null,
        target: null
      }
    };

    this.loadAccounts = this.loadAccounts.bind(this);
    this.clear = this.clear.bind(this);
    this.onChangeTarget = this.onChangeTarget.bind(this);
    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.addTransaction = this.addTransaction.bind(this);
    this.simulate = this.simulate.bind(this);
  }

  clear() {
    this.setState({
      transactions: [],
      error: null
    });
  }

  async loadAccounts() {
    let accounts = {};
    let error;
    console.log(`-> loading accounts...`);
    TABAPAY_ACCOUNT_IDS.forEach(accountID => {
      const request = (async() => {
        const result = await axios.get(`${PNX_API_BASE}/accounts/${accountID}`, {
          headers
        })
          .then(resp => { accounts[accountID] = resp.data; return resp.data; })
          .catch(err => error = err);
        // ...
        console.log(`    ${accountID} loaded, result: `, result);
      })();
    });

    console.log(`  << done loading accounts`);
    this.setState({ accounts, error });
    //await axios.get(`${PNX_API_BASE}/accounts/${accountID}`)
  }

  onChangeAmount(e) {
    console.log(`change amount event: `, e);
  }

  onChangeTarget(e) {
    console.log(`change target event: `, e);
  }

  addTransaction() {
    console.log(`--> NYI store transaction in state list`);
  }

  simulate() {
    console.log(`------------ NYI simulation --------------`);
  }




  /*
Payment succeeds                    4242 4242 4242 4242
Payment requires authentication     4000 0025 0000 3155
Payment is declined                 4000 0000 0000 9995
 */



  //- account boxes
  //- button to load accounts
  //- number field for amount
  //- button to submit transaction
  render() {
    const { accounts, transactions, error, tempTransaction } = this.state;

    const accountBoxes = Object.values(accounts).map(account => {
      const { referenceID, owner, card, bank } = account;
      return <div style={{border:'1px solid #ccc'}}>
               <b>{owner.name.first} {owner.name.last}</b> <i>...{card.last4} exp{card.expirationDate}</i>
               <br/><small>{referenceID}</small>
             </div>;
    });

    return (
      <div style={{margin:'0 auto',width:'600px'}}>
        <div><button onClick={async () => {
          await this.loadAccounts();
        }}>load account details...</button></div>
        <br/>

        {error ? <div style={{color:'red',textDecoration:'underline'}}>{error}</div> : null}

        {accountBoxes}

        {/* payment id: <input type="text" value={paymentId} */}
        {/*                    onChange={(e)=>{this.setState({paymentId: e.target.value});}} */}
        {/*        id="paymentId" width="30" /><br/> */}

        <div>
          <input type="number" name="transactionAmount" onChange={this.onChangeAmount} value={tempTransaction.amount} /><br/>
          <input type="text" name="transactionTarget" onChange={this.onChangeTarget} value={tempTransaction.target}/><br/>
          <button onClick={() => {
            this.addTransaction();
          }}>add transaction</button>
        </div>

        <div>
          <button onClick={() => {
            console.log(`-> NYI submit transaction to chosen employee and amount` );
          }}>SIMULATE DISBURSEMENT</button>
        </div>

        {/* <button onClick={() => {this.clear();}}>clear</button> */}

      </div>
    );
  }
};

ReactDOM.render(
  <React.StrictMode>
    {/* <div width="600" style={{textAlign:'right',margin:'0 auto',fontSize:'14px',padding:'44px'}}>
      <Tester />
    </div> */}

    {/* <div width="600" style={{textAlign:'right',margin:'0 auto',fontSize:'14px',padding:'44px'}}> */}
    {/*   <TabapayTester /> */}
    {/* </div> */}

    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
