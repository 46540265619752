import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import React, { Component } from 'react';
import { Dimmer, Divider, Grid, Icon, Loader } from 'semantic-ui-react';

import { YouTipContext, YouTipContextProvider } from './YouTipContextProvider';
import InstanceContainer from './components/InstanceContainer';

import './App.css';

/*
Payment succeeds                    4242 4242 4242 4242
Payment requires authentication     4000 0025 0000 3155
Payment is declined                 4000 0000 0000 9995
 */

const BlankContainer = () => {
  const cssUrl = "/themes/default.css";
  const style = document.createElement("link");
  style.href = cssUrl;
  style.rel = "stylesheet";
  style.async = true;
  document.head.appendChild(style);

  return (
        <Grid.Row>
          <Grid.Column>
            <Divider hidden />
            <Grid container centered>
              <Grid.Row>
                <Grid.Column textAlign='center'>
                  <img src="logo_large.png" height="50" alt="youtip" valign="bottom" />
                  <div style={{marginTop: '15px'}}>
                    <a href="https://www.youtip.money" style={{color:'#333'}}>
                      Check us out at www.youtip.money! <Icon name='right arrow circle' color='black' />
                    </a>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
  );
};

export default class App extends Component {
  render() {
    return <YouTipContextProvider>
             <Router>
               <div className="app-container">
                 <YouTipContext.Consumer>

                   {({ uiDisabled, uiMessage, ...info }) => {
                     return <>
                              {uiDisabled && uiMessage &&
                               <Dimmer active inverted>
                                 <Loader size='large'>{uiMessage}</Loader>
                               </Dimmer>
                              }
                              <Grid container centered>
                                <Switch>
                                  <Route path="/:slug" component={InstanceContainer} onLoaded={() => this.setState({ isReady: true })} />
                                  <Route path="/" component={BlankContainer} />
                                </Switch>
                              </Grid>
                            </>;
                   }}

                 </YouTipContext.Consumer>
               </div>
             </Router>
           </YouTipContextProvider>;
  }
}
