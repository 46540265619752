import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import YouTip from '../YouTip';

export default class RecipientInfo extends Component {
  render() {
    const {
      timestamp = new Date().toLocaleString(),
      name,
      info,
      service,
      hideTimestamp = false,
    } = this.props; // YouTip.currentInstance();

    let displayName;
    if (name.startsWith("Smoky Cabins Housekeeping:")) {
      const cabinName = name.replace("Smoky Cabins Housekeeping:", "").trim();
      displayName = (<span className='name'>Smoky Cabins Housekeeping:<br />{cabinName}</span>);
    } else {
      const pieces = name.split('<br>');
      const displayBody = pieces.map((piece, i) => {
        return <span key={i}>{piece}{i < pieces.length ? <br /> : null}</span>;
      });
      displayName = (<span className='name'>{displayBody}</span>);
    }

    return (
      <div id='recipient-info'>
        {hideTimestamp ? null : (<div className='timestamp'>{timestamp}</div>)}
        <Grid>
          {service ?
            <Grid.Row className='details' columns={2} stackable='true'>
              <Grid.Column>
                <span className='name'>{name}</span><br />
                <span className='service'>{service}</span>
              </Grid.Column>
              <Grid.Column className='info'>
                {info}
              </Grid.Column>
            </Grid.Row>
            :
            <Grid.Row className='details' stackable='true'>
              <Grid.Column>
                {displayName}
              </Grid.Column>
            </Grid.Row>
          }
        </Grid>
      </div>
    );
  }
}

RecipientInfo.propTypes = {
  timestamp: PropTypes.string,
  name: PropTypes.string,
  info: PropTypes.string,
  service: PropTypes.string
};
